/* eslint-disable no-use-before-define */
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { useSchools } from '../../context/Schools/useSchools';
import { useMeta } from '../../utils/jsonApiHelpers';

import SchoolYearDropdown from '../SchoolYearDropdown/SchoolYearDropdown';

const PupilDetails = ({
  formValues,
  setFormValues,
  valueMissing,
  handleInputChange,
  emailInvalid
}) => {
  const { focussedSchool } = useSchools();
  const { current_school_year: currentSchoolYear } = useMeta(focussedSchool);
  const { firstName, lastName, email, yearGroup } = formValues;
  const theme = useTheme();

  const firstNameField = () => (
    <TextField
      fullWidth
      required
      id="first-name"
      label="Pupil’s First Name"
      name="firstName"
      autoComplete="given-name"
      value={firstName}
      onChange={handleInputChange}
      error={valueMissing('firstName')}
      helperText={valueMissing('firstName') ? 'Please enter the pupil’s first name' : ''}
      sx={{ paddingBottom: 16 }}
    />
  );

  const lastNameField = () => (
    <TextField
      fullWidth
      required
      id="last-name"
      label="Pupil’s Last Name"
      name="lastName"
      autoComplete="family-name"
      value={lastName}
      onChange={handleInputChange}
      error={valueMissing('lastName')}
      helperText={valueMissing('lastName') ? 'Please enter the pupil’s last name' : ''}
      sx={{ paddingBottom: 16 }}
    />
  );

  const emailField = () => (
    <TextField
      fullWidth
      id="email"
      label="Parent’s Email Address"
      name="email"
      type="email"
      autoComplete="email"
      value={email}
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter a valid email address' : ''}
      sx={{ paddingBottom: 8 }}
    />
  );

  const handleYearChange = (event) => {
    setFormValues({ ...formValues, yearGroup: event.target.value });
  };

  const yearGroupField = () => (
    <SchoolYearDropdown
      handleYearChange={handleYearChange}
      selectedYear={yearGroup}
      valueMissing={valueMissing('yearGroup')}
    />
  );

  const currentSchoolYearStarted = () => `September ${currentSchoolYear}`;

  const tooltipText = () => {
    return `as of School Year (${currentSchoolYearStarted()})`;
  };

  const formFields = () => {
    return (
      <>
        <Stack direction="row" justifyContent="flex-start" alignItems="baseline" spacing={12}>
          {firstNameField()}
          {lastNameField()}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={12}
          mb={16}>
          {yearGroupField()}
          <Box width={{ xs: '10%', lg: '100%' }} height={48} display="flex" alignItems="center">
            <Tooltip title={tooltipText()} placement="right">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                fontSize={20}
                color={theme.palette.paintItBlack[40]}
              />
            </Tooltip>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" alignItems="baseline" spacing={12}>
          {emailField()}
          <Box width={{ xs: '10%', lg: '100%' }} />
        </Stack>

        <Typography variant="bodySecondary">
          Adding a parent or carer’s email will enable them to access the Parent Timeline where they
          will get updates on this child’s progress and also information about concerts. If you
          don’t provide this we will use the school admin email address in place of the parent email
          address.
        </Typography>
      </>
    );
  };

  return formFields();
};

export default PupilDetails;
