/* eslint-disable react/prop-types */
import { Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Page } from '@rocksteady-music-school/rms-ui';
import SchoolAPI from '../api/SchoolAPI';
import MatchFundingContent from '../components/MatchFundingContent/MatchFundingContent';
import response from '../consts/actions';
import contactDetails from '../consts/contactDetails';
import paths from '../consts/paths';
import ErrorPage from './ErrorPage';
import { useAuth } from '../context/Auth/useAuth';
import { useSchools } from '../context/Schools/useSchools';
import { useAttributes } from '../utils/jsonApiHelpers';
import usePageVisitLogger from '../hooks/usePageVisitLogger';

export default function MatchFundingPage() {
  const { user } = useAuth();
  const { admin } = useAttributes(user);
  const { focussedSchool, focussedSchoolTimetables, focussedSchoolIsActive } = useSchools();

  if (!(focussedSchoolIsActive && admin)) {
    return <ErrorPage content="pageNotFound" />;
  }

  usePageVisitLogger();

  const [matchedFundingData, setMatchFundingData] = useState({});
  const [fetchStatus, setFetchStatus] = useState(null);

  const { bursary_kids: bursaryKids, school_funded_kids: schoolFundedKids } = matchedFundingData;

  const numOfKids = (bursaryKids?.length || 0) + (schoolFundedKids?.length || 0);

  const dataHasBeenFetched = fetchStatus === response.SUCCESS;

  const fetchMatchFundingData = () => {
    setFetchStatus(response.LOADING);
    SchoolAPI.getMatchFundingData(focussedSchool.attributes.urn)
      .then((res) => {
        setMatchFundingData(res.data);
        setFetchStatus(response.SUCCESS);
      })
      .catch(() => {
        setFetchStatus(response.ERROR);
      });
  };

  useEffect(() => {
    if (focussedSchool) {
      fetchMatchFundingData();
    }
  }, [focussedSchool]);

  const timetableRate = useMemo(() => {
    if (focussedSchoolIsActive) {
      const highestTimetableRate = Math.max(
        ...focussedSchoolTimetables.map(({ attributes }) => attributes.rate_in_pennies)
      );
      return highestTimetableRate / 1.2; // 1.2 so not inc VAT
    }
    return null;
  }, [focussedSchoolTimetables]);

  const bannerTitle = () => {
    if (numOfKids > 0) {
      return `Thank you for supporting ${
        numOfKids > 1 ? `${numOfKids} pupils` : '1 pupil'
      } through the match funding scheme`;
    }
    return `For every pupil you fund, we'll match it! You're not match funding yet, here's the information you need to get started.`;
  };

  const bannerTitleColor = () => {
    if (numOfKids) {
      return 'greenOnions.140';
    }
    return 'mellowYellow.140';
  };

  const bannerDescription = () => {
    return (
      <>
        <Typography variant="body">
          Being part of a band offers children many benefits beyond learning an instrument, such as
          boosting confidence, enhancing emotional wellbeing, and improving school attendance. For
          children who’ve faced early life challenges, these benefits are even more profound,
          empowering them to thrive and succeed alongside their peers.
        </Typography>
        <br />
        <br />
        <Typography variant="body">
          That’s why, as part of our mission to remove barriers to music education, Rocksteady
          offers flexible match funding options for schools, so more children can experience the
          transformative power of learning music, regardless of their background or circumstances.
        </Typography>
        <br />
        <br />
        <Typography variant="body">
          Simply provide the details of the children you want to take part, and we’ll take care of
          everything else! For any questions, contact the{' '}
          <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
            <Typography variant="bodyLink">Schools Hotline</Typography>
          </a>{' '}
          or call{' '}
          <a href={`tel:${contactDetails.SCHOOLS_TEL}`}>
            <Typography variant="bodyLink">{contactDetails.SCHOOLS_TEL}</Typography>
          </a>{' '}
          .
        </Typography>
      </>
    );
  };

  const placesInfo = {
    title: bannerTitle(),
    color: bannerTitleColor(),
    description: bannerDescription(),
    kids: matchedFundingData,
    numOfKids
  };

  const description = () => (
    <>
      <Typography component="p" variant="h5">
        Double your impact
      </Typography>
      <Typography component="p" variant="body">
        Early access to music has the power to transform a child’s life. Rocksteady’s flexible match
        funding scheme is specifically designed to support children who would benefit most from
        inclusive and engaging music education, including children requiring financial assistance,
        those with SEND and SEMH needs, pupils with EAL, and children with additional behavioural
        needs. Through match funding, we{' '}
        <b>
          guarantee to match every school funded place with another free place, as well as offering
          one free up front bursary place to every school!
        </b>
      </Typography>
      <br />
      <br />
      <Typography component="p" variant="bodyBold">
        <em>
          So, if you choose to fund two children, Rocksteady will match that with two free places,
          in addition to your free initial bursary space. That’s five children accessing
          transformational music education for the price of two!
        </em>
      </Typography>
      <br />
      <br />
      <Typography component="p" variant="body">
        There are so many ways that children can benefit from Rocksteady. You can read all about
        this in our{' '}
        <a href={paths.IMPACT_SUMMARY} target="_blank" rel="noopener noreferrer">
          <Typography variant="bodyLink">Impact Summary.</Typography>
        </a>
      </Typography>
    </>
  );

  return (
    <Page error={fetchStatus === response.ERROR}>
      <MatchFundingContent
        placesInfo={placesInfo}
        description={description()}
        loading={!dataHasBeenFetched}
        timetableRate={timetableRate}
      />
    </Page>
  );
}
