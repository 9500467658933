const MatchFundingContentPricingTableData = (timetablePrice, theme, fullyFundedOption) => {
  const poundsAndPennies = (val) => {
    if (val === 0) {
      return '£0';
    }
    return `£${val.toFixed(2)}`;
  };

  const headers = [
    'School Funded Place',
    'Matched by Rocksteady',
    'Total ex Bursary ',
    'Monthly Cost',
    'Cost per child'
  ];

  const borderColor = theme.palette.whiteWedding.main;

  const columnColors = [
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.prettyInPink.main,
      bgColor: theme.palette.prettyInPink[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.greenOnions.main,
      bgColor: theme.palette.greenOnions[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    },
    {
      headerBgColor: theme.palette.blueSuedeShoes.main,
      bgColor: theme.palette.blueSuedeShoes[10],
      textColor: theme.palette.paintItBlack.main,
      headerTextColor: theme.palette.whiteWedding.main
    }
  ];

  let data;

  if (fullyFundedOption) {
    const fullyFunded = parseFloat((timetablePrice * 21) / 44);
    data = [
      [
        '21',
        '23',
        '44',
        poundsAndPennies(parseFloat(fullyFunded * 44)),
        poundsAndPennies(fullyFunded)
      ]
    ];
  } else {
    data = [...Array(10)].map((_, i) => {
      const fundedPlaces = i + 1;
      const bursaryPlaces = fundedPlaces;
      return [
        fundedPlaces.toString(),
        bursaryPlaces.toString(),
        (fundedPlaces + bursaryPlaces).toString(),
        poundsAndPennies(parseFloat(timetablePrice * fundedPlaces)),
        poundsAndPennies(fundedPlaces === 0 ? 0 : parseFloat(timetablePrice / 2))
      ];
    });
  }

  return {
    headers,
    borderColor,
    columnColors,
    data
  };
};

export default MatchFundingContentPricingTableData;
