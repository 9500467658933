/* eslint-disable no-use-before-define */
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography
} from '@mui/material';
import paths from '../../consts/paths';
import { useAuth } from '../../context/Auth/useAuth';
import { useSchools } from '../../context/Schools/useSchools';
import { useAttributes } from '../../utils/jsonApiHelpers';
import DateUtils from '../../utils/DateUtils';

const TermsCheckboxes = ({
  showValidationErrors,
  termsAccepted,
  setTermsAccepted,
  dpaAccepted,
  setDpaAccepted
}) => {
  const { schoolContact } = useAuth();
  const { focussedSchool } = useSchools();

  const { full_name_with_title: fullName } = useAttributes(schoolContact);

  const {
    name: schoolName,
    data_processing_agreement_in_place: dpaInPlace,
    data_processing_agreement_signed_by: dpaSignedBy,
    data_processing_agreement_signed_on: dpaSignedOn
  } = useAttributes(focussedSchool);

  const tcText = () => (
    <>
      I agree to Rocksteady Match-Funding Scheme{' '}
      <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
        <Typography variant="bodyLink">Terms of Service</Typography>
      </a>{' '}
      on behalf of my school and I’m authorised to do so.
    </>
  );

  const dpaText = () =>
    dpaInPlace ? (
      <>
        The{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        was agreed to on behalf of {schoolName} by {dpaSignedBy} on{' '}
        {DateUtils.formattedDateParts(dpaSignedOn).join(' ')}.
      </>
    ) : (
      <>
        I, {fullName}, have read and agree to the terms of the{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        on behalf of {schoolName} and am authorised to do so.
      </>
    );

  const tcCheckbox = () =>
    checkboxField(
      'terms',
      termsAccepted,
      setTermsAccepted,
      tcText(),
      'Please accept the Terms and Conditions before proceeding.',
      true
    );

  const dataProcessingCheckbox = () =>
    checkboxField(
      'dpa',
      dpaInPlace || dpaAccepted,
      setDpaAccepted,
      dpaText(),
      'Please agree to the processing of the child’s data.',
      true
    );

  const checkboxField = (name, accepted, setAccepted, text, errorText, required) => {
    const requiredAndMissing = required && showValidationErrors && !accepted;

    return (
      <FormControl required={required} error={requiredAndMissing} sx={{ display: 'block' }}>
        <FormControlLabel
          sx={{ display: 'table' }}
          control={
            <div style={{ display: 'table-cell' }}>
              <Checkbox
                disabled={name === 'dpa' && dpaInPlace}
                name={name}
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
                sx={{ color: requiredAndMissing ? 'error.main' : '' }}
              />
            </div>
          }
          label={
            <Typography variant="body" component="p">
              <Typography variant="body">{text}</Typography>
              <Typography variant="body" color="error.main">
                {required ? ' *' : ''}
              </Typography>
            </Typography>
          }
        />
        <FormHelperText>{showValidationErrors && !accepted ? errorText : ''}</FormHelperText>
      </FormControl>
    );
  };

  const checkboxes = () => {
    return (
      <Box mt={25}>
        {tcCheckbox()}
        {dataProcessingCheckbox()}
      </Box>
    );
  };

  return checkboxes();
};

export default TermsCheckboxes;
