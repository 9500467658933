/* eslint-disable no-use-before-define */
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@rocksteady-music-school/rms-ui';
import StringUtils from '../../utils/StringUtils';
import SchoolAPI from '../../api/SchoolAPI';
import externalPaths from '../../consts/externalPaths';
import UserAPI from '../../api/UserAPI';
import paths from '../../consts/paths';

const SignUpForm = ({ signUpValues, setSignUpValues }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [getRegistrationLoading, setGetRegistrationLoading] = useState(true);
  const [validTitles, setValidTitles] = useState([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [schools, setSchools] = useState([]);
  const [schoolSearchPending, setSchoolSearchPending] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [schoolFetchError, setSchoolFetchError] = useState(false);

  const { email, title, firstName, lastName, role, schoolsInHouseDatumId, optedInForMarketing } =
    signUpValues;

  const getNewRegistration = async () => {
    setGetRegistrationLoading(true);

    try {
      const { data } = await UserAPI.newRegistration();
      setValidTitles(data.meta.valid_titles);
    } finally {
      setGetRegistrationLoading(false);
    }
  };

  useEffect(() => {
    getNewRegistration();
  }, []);

  const emailValid = () => StringUtils.isEmailIsh(email);

  const formValid = () =>
    emailValid() && firstName && lastName && role && schoolsInHouseDatumId && termsAccepted;

  const emailInvalid = showValidationErrors && !emailValid();

  const valueMissing = (name) => {
    if (!showValidationErrors) return false;

    if (!signUpValues[name]) return true;

    // Check that it contains something other than whitespace
    return !signUpValues[name].trim();
  };

  const removeSchoolFetchError = () => setSchoolFetchError(false);

  const formatSchools = (data) => {
    return data.map((obj) => {
      return {
        id: obj.id,
        value: obj.attributes.name_with_town_and_postcode
      };
    });
  };

  const searchSchools = (query) => {
    removeSchoolFetchError();
    setSchoolSearchPending(true);
    SchoolAPI.findSchools(query)
      .then(({ data }) => setSchools(formatSchools(data.data)))
      .catch(() => setSchoolFetchError(true))
      .finally(() => setSchoolSearchPending(false));
  };

  const handleSearchChange = useCallback((event, query) => {
    if (event?.type === 'change') {
      setSearchQuery(query);
      if (query.length > 2) {
        searchSchools(query);
      } else {
        setSchools([]);
      }
    }
  }, []);

  const titleSelect = () => (
    <FormControl fullWidth>
      <InputLabel id="title-label">Title</InputLabel>
      <Select
        id="title"
        name="title"
        value={title}
        label="Title"
        labelId="title-label"
        data-testid="title-select"
        disabled={getRegistrationLoading}
        onChange={handleInputChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {validTitles.map((t) => (
          <MenuItem key={t} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const firstNameField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="first-name"
      label="First Name"
      name="firstName"
      autoComplete="given-name"
      autoFocus
      onChange={handleInputChange}
      error={valueMissing('firstName')}
      helperText={valueMissing('firstName') ? 'Please enter your first name' : ''}
    />
  );

  const lastNameField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="last-name"
      label="Last Name"
      name="lastName"
      autoComplete="family-name"
      onChange={handleInputChange}
      error={valueMissing('lastName')}
      helperText={valueMissing('lastName') ? 'Please enter your last name' : ''}
    />
  );

  const emailField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      type="email"
      onChange={handleInputChange}
      error={emailInvalid}
      helperText={emailInvalid ? 'Please enter your school email address' : ''}
    />
  );

  const roleField = () => (
    <TextField
      margin="normal"
      required
      fullWidth
      id="role"
      label="Role"
      name="role"
      autoComplete="role"
      onChange={handleInputChange}
      error={valueMissing('role')}
      helperText={valueMissing('role') ? 'Please enter your role' : ''}
    />
  );

  const getSchoolOptionKey = ({ id }) => id;
  const getSchoolOptionLabel = ({ value }) => value;
  const handleSelectingSchool = (_event, schoolOption) => {
    setSignUpValues({
      ...signUpValues,
      schoolsInHouseDatumId: schoolOption?.id?.toString() || ''
    });
  };

  const renderSchoolSearchInput = (params) => (
    <TextField
      {...params}
      fullWidth
      required
      label="School"
      name="school"
      error={valueMissing('schoolsInHouseDatumId') || schoolFetchError}
      helperText={valueMissing('schoolsInHouseDatumId') ? 'Please choose a school' : ''}
    />
  );

  const schoolField = () => (
    <Autocomplete
      id="schools-search"
      blurOnSelect
      loading={schoolSearchPending}
      getOptionKey={getSchoolOptionKey}
      getOptionLabel={getSchoolOptionLabel}
      options={schools}
      onInputChange={handleSearchChange}
      onChange={handleSelectingSchool}
      noOptionsText={message()}
      onBlur={removeSchoolFetchError}
      renderInput={renderSchoolSearchInput}
    />
  );

  const message = () => {
    if (searchQuery.length < 3) {
      return 'Type school name / postcode to search...';
    }

    if (schoolFetchError) return 'Error fetching schools';

    return 'No results found';
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const consideredValue = type === 'checkbox' ? checked : value;
    setShowValidationErrors(false);
    setSignUpValues({ ...signUpValues, [name]: consideredValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const afterSignUpPath =
      pathname === paths.RESOURCE_HUB_SIGN_UP
        ? paths.RESOURCE_HUB_SIGN_UP_CONFIRMATION
        : paths.SIGN_UP_CONFIRMATION;

    setShowValidationErrors(true);

    if (formValid()) {
      setErrorMsg(null);
      setSignUpLoading(true);

      const snakedParams = {
        registration: {
          email,
          title,
          first_name: firstName,
          last_name: lastName,
          job_title: role,
          schools_in_house_datum_id: schoolsInHouseDatumId,
          opted_in_for_marketing: optedInForMarketing
        }
      };

      UserAPI.signUp(snakedParams)
        .then(({ data }) => {
          navigate(afterSignUpPath, {
            state: { firstName, email, registrationResponse: data }
          });
        })
        .catch((err) => {
          setErrorMsg(err.response?.data?.error || err.message);
          setSignUpLoading(false);
        });
    }
  };

  const marketingOptInCheckbox = () => (
    <FormControl component="fieldset" sx={{ display: 'block' }} variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={optedInForMarketing}
              onChange={handleInputChange}
              name="optedInForMarketing"
            />
          </div>
        }
        label={
          <Typography variant="body">
            I would like to receive updates about new resources available, including Feel Good{' '}
            Friday.
          </Typography>
        }
      />
    </FormControl>
  );

  const tcCheckbox = () => (
    <FormControl
      required
      error={showValidationErrors && !termsAccepted}
      component="fieldset"
      sx={{ display: 'block' }}
      variant="standard">
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              name="terms"
            />
          </div>
        }
        label={
          <Typography variant="body" component="p">
            I agree to the{' '}
            <a href={externalPaths.PRIVACY_POLICY} target="_blank" rel="noreferrer">
              <Typography variant="bodyLink">privacy policy</Typography>
            </a>{' '}
            and the{' '}
            <a href={externalPaths.TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
              <Typography variant="bodyLink">terms and conditions</Typography>
            </a>
            .
          </Typography>
        }
      />
      {showValidationErrors && !termsAccepted && (
        <FormHelperText>Please accept the terms first</FormHelperText>
      )}
    </FormControl>
  );

  const renderErrorAlert = () => (
    <Alert severity="error" sx={styles.errorAlert}>
      {Array.isArray(errorMsg) ? errorMsg.join('. ') : errorMsg}
    </Alert>
  );

  const signUpForm = () => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Stack spacing={19} mt={27} mb={10} sx={styles.formGroup}>
          {errorMsg && renderErrorAlert()}

          <Stack direction="row" justifyContent="center" alignItems="center" spacing={12}>
            {titleSelect()}
            {firstNameField()}
          </Stack>

          {lastNameField()}

          {emailField()}
          {roleField()}
          {schoolField()}
        </Stack>
        {tcCheckbox()}
        {marketingOptInCheckbox()}

        <Button
          type="submit"
          label="Create an account"
          style={styles.submitBtn}
          loading={signUpLoading}
          color="secondary"
        />
      </Box>
    );
  };

  return signUpForm();
};

const styles = {
  formGroup: { width: { lg: '75%', md: '75%', xs: '100%' } },
  passwordLink: { mb: 20, mt: 12 },
  submitBtn: { width: 'fit-content', marginTop: 24, marginBottom: 23 },
  errorAlert: { mb: 5 }
};

export default SignUpForm;
