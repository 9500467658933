/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Alert, Grid, Typography, CardContent, useTheme } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Button, Table, Box } from '@rocksteady-music-school/rms-ui';
import MatchFundingForm from '../MatchFundingForm/MatchFundingForm';
import handMail from '../../assets/images/hand_mail.svg';
import pen from '../../assets/images/pen.svg';
import user from '../../assets/images/user_plus.svg';
import scroll from '../../assets/images/scroll.svg';
import MatchFundingModal from '../MatchFundingModal/MatchFundingModal';
import MatchFundingContentPricingTableData from './MatchFundingContentPricingTableData';
import DateUtils from '../../utils/DateUtils';

const infographicTitle = 'Key things to know';

const infographicItems = [
  {
    title: 'Flexible to suit your school',
    description: 'You can add, edit and remove places whenever you choose.',
    icon: user
  },
  {
    title: 'Music Performance in Bands qualification',
    description:
      'Match funded children can be enrolled in Rocksteady’s exam-free, Ofqual-regulated music qualification, accredited by Trinity College London. A parent/guardian email address is required to enrol a child in the qualification, and by doing so, their parent/guardian will also be able to share in their musical journey and achievements through the termly Rocksteady Round-up.',
    icon: scroll
  },
  {
    title: 'Dedicated support',
    description:
      'Whether you’re looking to support your SEN pupils, increase attendance or boost parental engagement, your dedicated schools experience specialist is on hand to talk about how to maximise the impact on your pupils.',
    icon: handMail
  },
  {
    title: 'Lesson impact',
    description:
      'Children will have exactly the same experience as parent funded children. To see the impact of lessons we recommend children are enrolled for a minimum of a term. Staying longer will allow them to take part in our unique exam-free music qualification.',
    icon: pen
  },
  {
    title: 'Termly invoices',
    description: 'Payment due on receipt.',
    icon: scroll
  }
];

// Function to check if the current date is between three days before the cutoff msg date and the end of August
const showEnrolmentCutoffMsg = () => {
  // NOTE: this env var is not the cutoff date itself, but the date used in the message we display:
  // "Any requests made after your lesson week commencing {date} will be enrolled for September."
  const cutoffMsgDateStr = process.env.REACT_APP_ENROLMENT_CUTOFF_MSG_DATE;

  if (!cutoffMsgDateStr) return false;

  const cutoffDate = new Date(cutoffMsgDateStr);
  const endOfAugust = new Date(cutoffDate.getFullYear(), 7, 31); // Month is 0-indexed, so 7 is August

  // Show the message a few days before the cutoff msg date so that they are aware of it
  const startDate = new Date(cutoffDate);
  startDate.setDate(startDate.getDate() - 3);

  return DateUtils.isWithinDateRange(startDate, endOfAugust);
};

// Function to format the cutoff msg date as "17th of June"
const formatCutoffMsgDate = () => {
  const cutoffMsgDateStr = process.env.REACT_APP_ENROLMENT_CUTOFF_MSG_DATE;
  if (!cutoffMsgDateStr) return null;

  return DateUtils.ordinalDateWithMonth(cutoffMsgDateStr);
};

export default function MatchFundingContent({ placesInfo, description, timetableRate }) {
  const [formVisible, setFormVisible] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const timetablePrice = useMemo(() => {
    return timetableRate && parseFloat(timetableRate / 100).toFixed(2);
  }, [timetableRate]);

  const theme = useTheme();

  const matchPricingTableData =
    timetablePrice && MatchFundingContentPricingTableData(timetablePrice, theme, false);

  const matchFullyFundedHalfDayTableData =
    timetablePrice && MatchFundingContentPricingTableData(timetablePrice, theme, true);

  useEffect(() => {
    if (formVisible) {
      document.getElementById('bursary-sf-form').scrollIntoView({ behavior: 'smooth' });
    }
  }, [formVisible]);

  const formIntro = () => (
    <>
      <Typography variant="body" component="p" marginY={16}>
        To register a child in the Match-Funding scheme, fill in their details below and the Schools
        team will make their best effort to enrol them in a age-appropriate band. You will be
        invoiced in line with the Match-Funding Scheme based on the total number of children
        enrolled i.e. if you have 4 children enrolled, you will only pay for 2.
      </Typography>

      {showEnrolmentCutoffMsg() && formatCutoffMsgDate() && (
        <Alert severity="warning">
          Any requests made after your lesson week commencing the {formatCutoffMsgDate()} will be
          enrolled for September.
        </Alert>
      )}

      <Typography variant="body" component="p" marginY={16}>
        <Typography variant="body" color="rubyTuesday.110">
          *
        </Typography>{' '}
        Mandatory field
      </Typography>
    </>
  );

  const title = () => (
    <Typography variant="h1" mt={{ xs: 0, md: 24 }} mb={24}>
      Match funding with Rocksteady - No child left behind
    </Typography>
  );

  const openTheForm = () => {
    setFormVisible(true);
    // If the form was already open on the page, we need to scroll it into view when the modal closes.
    // Somehow this doesn't work when you do it from the modal unless it has a bit of a timeout,
    // which is why we're waiting 100ms below before doing it
    setTimeout(() => {
      document.getElementById('bursary-sf-form').scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const handleFirstBtnClick = () => openTheForm();
  const handleOpeningStatusModal = () => setStatusModalOpen(true);
  const handleModalClose = () => setStatusModalOpen(false);

  const pageContent = () => (
    <>
      {title()}
      <Box
        sx={{
          border: '2px solid #E5FDF7',
          boxShadow: 'none',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 20,
          p: 0
        }}>
        <CardContent
          sx={{
            width: { xs: 'auto', md: '100%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            py: 32,
            pl: 24
          }}>
          <Typography component="p" variant="h3" color={placesInfo.color}>
            {placesInfo.title}
          </Typography>
          <br />
          <Typography variant="body" component="p">
            {placesInfo.description}
          </Typography>
          <Box
            gap={10}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            p={0}
            mt={description ? 24 : 32}>
            <Button color="secondary" onClick={handleFirstBtnClick} label="Register a Pupil" />
            <Button
              color="primary"
              outlined
              onClick={handleOpeningStatusModal}
              label="Check Enrolled Pupils"
            />
          </Box>
        </CardContent>
      </Box>
      <Grid container>
        <Grid item p={24}>
          <Box>{description}</Box>
        </Grid>
      </Grid>
      <Box mb={24} bgcolor="#E5FDF7" boxShadow="none" borderRadius="10px" p={24}>
        <Typography variant="h4" mb={16}>
          {infographicTitle}
        </Typography>
        <Box bgcolor="white" boxShadow="none" borderRadius="10px" p={24}>
          <Typography variant="bodyBold" component="p">
            {infographicItems[0].title}
          </Typography>
          <Typography variant="body" component="p">
            {infographicItems[0].description}
          </Typography>
        </Box>
        <Box
          display="grid"
          gridAutoColumns="minmax(0, 1fr)"
          gridAutoRows="minmax(0, 1fr)"
          gridAutoFlow={{ xs: 'row', md: 'column' }}
          gap={16}
          sx={{ marginBottom: '12px', marginTop: '12px' }}>
          <Box bgcolor="white" boxShadow="none" borderRadius="10px" p={24}>
            <Typography variant="bodyBold" component="p">
              {infographicItems[1].title}
            </Typography>
            <Typography variant="body" component="p">
              {infographicItems[1].description}
            </Typography>
          </Box>
          <Box bgcolor="white" boxShadow="none" borderRadius="10px" p={24}>
            <Typography variant="bodyBold" component="p">
              {infographicItems[2].title}
            </Typography>
            <Typography variant="body" component="p">
              {infographicItems[2].description}
            </Typography>
          </Box>
        </Box>
        <Box
          display="grid"
          gridAutoColumns="minmax(0, 1fr)"
          gridAutoRows="minmax(0, 1fr)"
          gridAutoFlow={{ xs: 'row', md: 'column' }}
          gap={16}>
          <Box bgcolor="white" boxShadow="none" borderRadius="10px" p={24}>
            <Typography variant="bodyBold" component="p">
              {infographicItems[3].title}
            </Typography>
            <Typography variant="body" component="p">
              {infographicItems[3].description}
            </Typography>
          </Box>
          <Box bgcolor="white" boxShadow="none" borderRadius="10px" p={24}>
            <Typography variant="bodyBold" component="p">
              {infographicItems[4].title}
            </Typography>
            <Typography variant="body" component="p">
              {infographicItems[4].description}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        id="bursary-sf-form"
        padding={40}
        mb={24}
        borderRadius="10px"
        sx={{ display: formVisible ? 'block' : 'none', border: '2px solid #D2F0FB' }}>
        <Typography variant="h2">REGISTER PUPILS - school funded and Bursary spaces</Typography>
        <Grid container>
          <Grid item xs={12} lg={9.5}>
            {formIntro()}
          </Grid>
        </Grid>
        <MatchFundingForm />
      </Box>

      {statusModalOpen && (
        <MatchFundingModal
          open={statusModalOpen}
          onClose={handleModalClose}
          placesInfo={placesInfo}
          setFormVisible={openTheForm}
        />
      )}

      {matchPricingTableData && (
        <>
          <Typography variant="h5" component="p" marginY={16}>
            Your gift from Rocksteady!
          </Typography>
          <Table
            data={[['Bursary place', 'Free']]}
            columnColors={[
              {
                bgColor: theme.palette.prettyInPink.main,
                textColor: theme.palette.whiteWedding.main
              },
              {
                bgColor: theme.palette.blueSuedeShoes.main,
                textColor: theme.palette.whiteWedding.main
              }
            ]}
            maxWidth={920}
            borderColor={theme.palette.whiteWedding.main}
          />
        </>
      )}

      {matchPricingTableData && timetablePrice && (
        <>
          <Typography variant="h5" component="p" marginY={16}>
            Double your impact by match funding
          </Typography>
          <Table {...matchPricingTableData} maxWidth={920} />
          <Typography variant="body" component="p" marginY={16}>
            *prices excl VAT
          </Typography>
          <Typography variant="h5" component="p" marginY={16}>
            Fully funded half day
          </Typography>
          <Table {...matchFullyFundedHalfDayTableData} maxWidth={920} />
          <Typography variant="body" component="p" marginY={16}>
            *prices excl VAT
          </Typography>
        </>
      )}
    </>
  );

  return pageContent();
}
