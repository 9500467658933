/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Box, Grid, Stack, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { Card, Page } from '@rocksteady-music-school/rms-ui';
import thumbnail from '../assets/images/invite_members.png';
import { useAuth } from '../context/Auth/useAuth';
import { useSchools } from '../context/Schools/useSchools';
import { useAttributes } from '../utils/jsonApiHelpers';
import contactDetails from '../consts/contactDetails';
import ChangePasswordForm from '../components/ChangePasswordForm/ChangePasswordForm';
import UpdateUserPrefsSection from '../components/UpdateUserPrefsSection/UpdateUserPrefsSection';
import PermissionChangeSection from '../components/PermissionChangeSection/PermissionChangeSection';
import { shareFGFViaEmail } from './FeelGoodFridayPage';

const TABS = [
  {
    name: 'your profile',
    color: 'blueSuedeShoes.10'
  },
  {
    name: 'school preferences',
    color: 'yellowSubmarine.40'
  }
];

export default function AccountSettingsPage() {
  const { schoolContact, user } = useAuth();
  const { focussedSchoolIsActive } = useSchools();
  const { admin } = useAttributes(user);
  const { full_name_with_title: fullName, email } = useAttributes(schoolContact);

  const [selectedTab, setSelectedTab] = useState(TABS[0]);

  const accountItem = (title, subtitle) => (
    <Stack spacing={8}>
      <Typography variant="bodyBold" component="p">
        {title}
      </Typography>
      <Typography variant="body" component="p" color="fadeToGrey.main">
        {subtitle}
      </Typography>
    </Stack>
  );

  const pageTabs = () => {
    let tabsForDisplay = [];
    if (focussedSchoolIsActive && admin) {
      tabsForDisplay = TABS;
    } else {
      tabsForDisplay = [TABS[0]];
    }

    return (
      <Box display="flex" width="100%">
        {tabsForDisplay.map((tab) => (
          <Box
            key={uuidv4()}
            backgroundColor={tab.color}
            sx={styles.tab}
            onClick={() => setSelectedTab(tab)}>
            <Typography variant="h4">{tab.name}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const yourProfile = () => (
    <>
      <Typography variant="h2" color="primary.main">
        Your Profile
      </Typography>
      <Stack spacing={24}>
        {accountItem('Name', fullName)}
        {accountItem('Email Address', email)}
        {focussedSchoolIsActive && <PermissionChangeSection />}
      </Stack>
      <UpdateUserPrefsSection type="user" />
      <ChangePasswordForm />
    </>
  );

  const schoolPreferences = () => (
    <>
      <Typography variant="h2" color="primary.main">
        School Preferences
      </Typography>
      <UpdateUserPrefsSection type="school" />
    </>
  );

  const accountSettingsTabs = () => (
    <>
      {pageTabs()}
      <Stack
        borderRadius="25px"
        bgcolor={selectedTab.color}
        spacing={32}
        px={32}
        pt={56}
        pb={32}
        mb={32}
        maxWidth={{ md: '80%' }}
        style={{ borderTopLeftRadius: 0 }}>
        {selectedTab.name === 'your profile' ? yourProfile() : schoolPreferences()}
      </Stack>
    </>
  );

  const titleAndDescription = () => (
    <Stack spacing={16} mb={32}>
      <Typography variant="h1">Account Settings</Typography>
      <Typography variant="body" maxWidth={{ lg: '85%' }}>
        Update your personal details for your School Portal Account.
      </Typography>
      <Typography variant="body" component="p">
        <Typography variant="bodyBold">Need help with your account?</Typography>{' '}
        <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
          <Typography variant="bodyLink">Contact Schools Hotline</Typography>
        </a>
      </Typography>
    </Stack>
  );

  const inviteMembersCard = () => (
    <Box maxWidth={450}>
      <Card
        altText="kid on guitar"
        buttonProps={{ color: 'secondary', onClick: shareFGFViaEmail, label: 'Invite Members' }}
        thumbnail={thumbnail}
        summary="Click to give other staff at your school access to Feel Good Friday."
        title="Know a teacher?"
      />
    </Box>
  );

  return (
    <Page>
      <Grid container spacing={{ lg: 24 }} pt={{ lg: 24 }}>
        <Grid item sm={12} md={7}>
          {titleAndDescription()}
          {accountSettingsTabs()}
        </Grid>
        <Grid item sm={12} md={5} display="flex" justifyContent={{ sm: 'center', md: 'flex-end' }}>
          {inviteMembersCard()}
        </Grid>
      </Grid>
    </Page>
  );
}

const styles = {
  tab: {
    padding: '16px 32px 12px 32px',
    borderRadius: '25px 25px 0px 0px',
    cursor: 'pointer'
  }
};
