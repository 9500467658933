/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { camelCase, isEqual } from 'lodash';
import { Button } from '@rocksteady-music-school/rms-ui';
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../../context/Auth/useAuth';
import { useSchools } from '../../context/Schools/useSchools';
import { useAttributes } from '../../utils/jsonApiHelpers';
import paths from '../../consts/paths';
import DateUtils from '../../utils/DateUtils';
import SatsPreferences from './SatsPreferences';

function RecruitmentTooltipText() {
  return (
    <Box maxWidth={500}>
      <p>
        Supporting us in our Bandleader recruitment process is a great way to receive some free
        Rocksteady lessons at your school (especially for kids not already enrolled with us)!
      </p>
      <p>
        If this sounds like something you would be able to help us with, ensure you have this option
        ticked, and we will likely be in touch to talk you through the process.
      </p>
    </Box>
  );
}

export default function UpdateUserPrefsSection({ type }) {
  const { schoolContact, updateSchoolContact } = useAuth();
  const { focussedSchool, updateFocussedSchool } = useSchools();

  const { full_name_with_title: fullName, opted_in_for_marketing: optedInForMarketing } =
    useAttributes(schoolContact);

  const {
    name: schoolName,
    data_processing_agreement_in_place: dpaInPlace,
    data_processing_agreement_signed_by: dpaSignedBy,
    data_processing_agreement_signed_on: dpaSignedOn,
    sats_preference: satsPreference,
    ks1_sats_on_rs_days: ks1Sats,
    sats_room_change_notes: satsRoomNotes,
    can_host_recruitment_lessons: canHostRecruitmentLessons
  } = useAttributes(focussedSchool);

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const [optingInForMarketing, setOptingInForMarketing] = useState(optedInForMarketing);
  const [dpaAccepted, setDpaAccepted] = useState(dpaInPlace || false);
  const [satsPreferences, setSatsPreferences] = useState({
    satsPreference,
    ks1Sats,
    satsRoomNotes
  });
  const [willingToHostRecruitmentLessons, setWillingToHostRecruitmentLessons] =
    useState(canHostRecruitmentLessons);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ user: null, school: null });

  useEffect(() => {
    closeSnackbar();
  }, [dpaAccepted, optingInForMarketing]);

  const satsPreferencesHaveChanged = useMemo(
    () => !isEqual({ satsPreference, ks1Sats, satsRoomNotes }, satsPreferences),
    [focussedSchool, satsPreferences]
  );

  const handleErrorResponse = (error) => {
    if (error.response?.data?.errors) {
      setErrors((currentErrors) => ({
        ...currentErrors,
        [type]: [error.response.data.errors].flat()
      }));
    } else {
      setErrors((currentErrors) => ({ ...currentErrors, [type]: [error.message].flat() }));
    }
  };

  const displaySnackMessage = () =>
    enqueueSnackbar('Done! Your preferences have been updated.', { variant: 'success' });

  const updateMarketingPreferences = () => {
    const params = {
      school_contact: { opted_in_for_marketing: optingInForMarketing }
    };

    updateSchoolContact(params)
      .then(displaySnackMessage)
      .catch(handleErrorResponse)
      .finally(() => setLoading(false));
  };

  const updateSihd = () => {
    const params = {
      schools_in_house_datum: {
        data_processing_agreement_in_place: dpaAccepted,
        sats_preference: satsPreferences.satsPreference,
        ks1_sats_on_rs_days: satsPreferences.ks1Sats,
        sats_room_change_notes: satsPreferences.satsRoomNotes || null,
        can_host_recruitment_lessons: willingToHostRecruitmentLessons
      }
    };

    updateFocussedSchool(params)
      .then(() => {
        displaySnackMessage();
      })
      .catch(handleErrorResponse)
      .finally(() => setLoading(false));
  };

  const schoolPrefsChanged = useMemo(
    () =>
      dpaAccepted !== dpaInPlace ||
      satsPreferencesHaveChanged ||
      canHostRecruitmentLessons !== willingToHostRecruitmentLessons,
    [
      dpaAccepted,
      dpaInPlace,
      satsPreferencesHaveChanged,
      canHostRecruitmentLessons,
      willingToHostRecruitmentLessons
    ]
  );

  const handleSubmit = () => {
    setErrors({ user: null, school: null });
    setLoading(true);

    if (optingInForMarketing !== optedInForMarketing) updateMarketingPreferences();
    if (schoolPrefsChanged) updateSihd();
  };

  const alertMsgs = () =>
    errors[type].length > 1 ? (
      <ul style={{ listStylePosition: 'inside', paddingLeft: 0, marginTop: 8, marginBottom: 2 }}>
        {errors[type].map((er) => (
          <li key={uuidv4()}>{er}</li>
        ))}
      </ul>
    ) : (
      errors[type]
    );

  const renderErrorAlert = () => (
    <Alert severity="error">
      <AlertTitle>Error saving preferences</AlertTitle>
      {alertMsgs(type)}
    </Alert>
  );

  const checkboxField = (accepted, setAccepted, text, disabled = false) => {
    return (
      <FormGroup sx={{ display: 'block' }}>
        <FormControlLabel
          sx={{ display: 'table' }}
          control={
            <div style={{ display: 'table-cell' }}>
              <Checkbox
                disabled={disabled || loading}
                name={type}
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
              />
            </div>
          }
          label={
            <Typography variant="body" component="p" color="fadeToGrey.main">
              {text}
            </Typography>
          }
        />
      </FormGroup>
    );
  };

  const dpaSignedDetails = () => {
    if (dpaSignedBy && dpaSignedOn)
      return ` by ${dpaSignedBy} on ${DateUtils.formattedDateParts(dpaSignedOn).join(' ')}`;

    return '';
  };

  const dpaText = () =>
    dpaInPlace ? (
      <>
        The{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        was agreed to on behalf of {schoolName}
        {dpaSignedDetails()}.
      </>
    ) : (
      <>
        I, {fullName}, have read and agree to the terms of the{' '}
        <a href={paths.KEY_RESOURCES} target="_blank" rel="noreferrer">
          <Typography variant="bodyLink">Data Processing Agreement</Typography>
        </a>{' '}
        on behalf of {schoolName} and am authorised to do so.
      </>
    );

  const dataProcessingCheckbox = () =>
    checkboxField(
      dpaInPlace || dpaAccepted,
      setDpaAccepted,
      dpaText(),
      type === 'school' && dpaInPlace
    );

  const marketingCheckbox = () =>
    checkboxField(
      optingInForMarketing,
      setOptingInForMarketing,
      'I would like to receive updates about new resources available, including Feel Good Friday.'
    );

  const dpaSection = () => (
    <Stack spacing={8}>
      <Typography variant="bodyBold" component="p">
        Data Processing Agreement
      </Typography>
      {dataProcessingCheckbox()}
    </Stack>
  );

  const recruitmentLessonsToolTip = () => (
    <Tooltip arrow title={<RecruitmentTooltipText />} placement="top">
      <InfoIcon sx={{ color: 'paintItBlack.40' }} />
    </Tooltip>
  );

  const recruitmentLessonsSection = () => (
    <Stack spacing={8}>
      <Box display="flex" alignItems="center">
        <Typography variant="bodyBold" component="p">
          Hosting Recruitment Lessons
        </Typography>
        {recruitmentLessonsToolTip()}
      </Box>
      {checkboxField(
        willingToHostRecruitmentLessons,
        setWillingToHostRecruitmentLessons,
        'We are open to supporting Rocksteady with the hosting of Recruitment Lessons.'
      )}
      {!canHostRecruitmentLessons && willingToHostRecruitmentLessons && (
        <Alert severity="info">We will be in touch with you shortly to discuss.</Alert>
      )}
    </Stack>
  );

  const renderSubmitButton = () => (
    <Button
      label="Save Changes"
      onClick={handleSubmit}
      color="secondary"
      style={{ width: 'fit-content' }}
      loading={loading}
      disabled={(optingInForMarketing === optedInForMarketing && !schoolPrefsChanged) || loading}
    />
  );

  const marketingSection = () => (
    <Stack spacing={8} onSubmit={handleSubmit}>
      <Typography variant="bodyBold" component="p">
        Marketing Preferences
      </Typography>
      {errors.user && renderErrorAlert()}
      {marketingCheckbox()}
    </Stack>
  );

  const handleSatsPreferenceChange = ({ target }) => {
    const { name, value, checked } = target;
    let consideredValue = value;
    if (name === 'ks1-sats') consideredValue = checked;
    setSatsPreferences((prev) => ({ ...prev, [camelCase(name)]: consideredValue }));
  };

  const schoolPreferences = () => (
    <>
      {dpaSection()}
      {recruitmentLessonsSection()}
      <SatsPreferences
        satsPreferences={satsPreferences}
        onSatsPreferenceChange={handleSatsPreferenceChange}
      />
      {errors.school && renderErrorAlert()}
    </>
  );

  return (
    <Stack spacing={24}>
      {type === 'user' ? marketingSection() : schoolPreferences()}
      {renderSubmitButton()}
    </Stack>
  );
}
