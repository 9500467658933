import * as React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import AuthProvider from './context/Auth/AuthProvider';
import SchoolsProvider from './context/Schools/SchoolsProvider';
import RequireAuth from './components/RequireAuth';
import AuthLayout from './routes/AuthLayout';
import Layout from './routes/Layout';
import ErrorPage from './routes/ErrorPage';
import LoginPage from './routes/LoginPage';
import ContactsPage from './routes/ContactsPage';
import FeelGoodFridayPage from './routes/FeelGoodFridayPage';
import SignUpConfirmationPage from './routes/SignUpConfirmationPage';
import SignUpPage from './routes/SignUpPage';
import paths from './consts/paths';
import RequestPasswordResetPage from './routes/RequestPasswordResetPage';
import SetPasswordPage from './routes/SetPasswordPage';
import AccountSettingsPage from './routes/AccountSettingsPage';
import CookiePolicyPage from './routes/CookiePolicyPage';
import ImpactReportPage from './routes/ImpactReportPage';
import BandLessonsPage from './routes/BandLessonsPage';
import MatchFundingPage from './routes/MatchFundingPage';
import KeyResourcesPage from './routes/KeyResourcesPage';
import UnlockAccountPage from './routes/UnlockAccountPage';
import ResourceHubVideoPage from './routes/ResourceHubVideoPage';
import LayoutHub from './routes/LayoutHub';
import ResourceHubPage from './routes/ResourceHubPage';
import ResourceHubSignUpPage from './routes/ResourceHubSignUpPage';
import ResourceHubSignUpConfirmationPage from './routes/ResourceHubSignUpConfirmationPage';
import ResourceHubLoginPage from './routes/ResourceHubLoginPage';
import HomePage from './routes/HomePage';

function ResourceHubVideoPageWrapper() {
  const { slug } = useParams();
  return <ResourceHubVideoPage key={slug} />;
}

export default function App() {
  const releaseHub = process.env.REACT_APP_RELEASE_RESOURCE_HUB === 'true';

  return (
    <AuthProvider>
      <SchoolsProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="*" element={<ErrorPage content="pageNotFound" />} />
            <Route element={<AuthLayout />}>
              <Route path={paths.LOG_IN} element={<LoginPage />} />
              <Route path={paths.SIGN_UP} element={<SignUpPage />} />
              <Route path={paths.SIGN_UP_CONFIRMATION} element={<SignUpConfirmationPage />} />
              <Route path={paths.REQUEST_PASSWORD_RESET} element={<RequestPasswordResetPage />} />
              <Route path={paths.SET_PASSWORD} element={<SetPasswordPage />} />
              <Route path={paths.UNLOCK_ACCOUNT} element={<UnlockAccountPage />} />
            </Route>

            <Route
              path={paths.ROOT}
              element={
                <RequireAuth>
                  <HomePage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.FEEL_GOOD_FRIDAY}
              element={
                <RequireAuth>
                  <FeelGoodFridayPage />
                </RequireAuth>
              }
            />
            <Route
              path={paths.BAND_LESSONS}
              element={
                <RequireAuth>
                  <BandLessonsPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.CONTACTS}
              element={
                <RequireAuth>
                  <ContactsPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.ACCOUNT_SETTINGS}
              element={
                <RequireAuth>
                  <AccountSettingsPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.MATCH_FUNDING}
              element={
                <RequireAuth>
                  <MatchFundingPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.IMPACT_REPORT}
              element={
                <RequireAuth>
                  <ImpactReportPage />
                </RequireAuth>
              }
            />

            <Route path={paths.COOKIE_POLICY} element={<CookiePolicyPage />} />

            <Route
              path={paths.KEY_RESOURCES}
              element={
                <RequireAuth>
                  <KeyResourcesPage />
                </RequireAuth>
              }
            />
            {/* "/documents" was old path to what is now "/key-resources" */}
            <Route path="/documents" element={<Navigate to={paths.KEY_RESOURCES} replace />} />
          </Route>
          {releaseHub && (
            <Route element={<LayoutHub />}>
              <Route path={paths.RESOURCE_HUB_VIDEO} element={<ResourceHubVideoPageWrapper />} />
              <Route path={paths.RESOURCE_HUB} element={<ResourceHubPage />} />
              <Route path={paths.RESOURCE_HUB_SIGN_UP} element={<ResourceHubSignUpPage />} />
              <Route
                path={paths.RESOURCE_HUB_SIGN_UP_CONFIRMATION}
                element={<ResourceHubSignUpConfirmationPage />}
              />
              <Route path={paths.RESOURCE_HUB_LOGIN} element={<ResourceHubLoginPage />} />
            </Route>
          )}
        </Routes>
      </SchoolsProvider>
    </AuthProvider>
  );
}
