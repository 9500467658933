/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */

import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import ReactMarkdown from 'react-markdown';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { camelCase } from 'lodash';
import { useState, useEffect } from 'react';
import FGFDownloadButton from './FGFDownloadButton';
import fgfPlaceholder from '../../assets/images/fgf_placeholder.png';
import videoPlay from '../../assets/images/video_play.svg';
import CookieUtils from '../../utils/CookieUtils';
import SchoolsUserEventLogAPI from '../../api/SchoolsUserEventLogAPI';
import { useSchools } from '../../context/Schools/useSchools';
import ROCKSTEADY_AGE_GROUPS from '../../consts/rocksteadyAgeGroups';

const BASE_EVENT_LOG_PARAMS = { event_type: 'feelgoodfriday', value: 1 };

export default function FGFEpisodeContent({ bgColor, data, loading }) {
  const theme = useTheme();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const handleVideoLoaded = () => setVideoLoaded(true);
  const [videoError, setVideoError] = useState(false);
  const handleVideoError = () => setVideoError(true);
  const [didPlay, setDidPlay] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  const { focussedSchool, focussedSchoolIsActive } = useSchools();

  const fileUrl = data?.linked_resource?.data?.attributes?.file_url;

  const currentLogParams = {
    school_is_active_rs: focussedSchoolIsActive,
    urn: focussedSchool.attributes.urn,
    description: data?.vimeo_id,
    age_group: data?.age_group
  };

  const postLogEvent = (eventType, explicitParams) => {
    SchoolsUserEventLogAPI.createLog({
      schools_user_event_log: {
        ...BASE_EVENT_LOG_PARAMS,
        event_item: eventType,
        ...(explicitParams || currentLogParams)
      }
    });
  };

  const handlePlay = () => {
    postLogEvent('play');
    setDidPlay(true);
  };

  const handleEnd = () => {
    postLogEvent('finish');
  };

  useEffect(() => {
    setVideoLoaded(false);
    setVideoError(false);
    setDidPlay(false);
  }, [data]);

  useEffect(() => {
    if (didPlay) {
      const handleUnload = (event) => {
        event.preventDefault();
        postLogEvent('finish', currentLogParams);
      };

      window.addEventListener('beforeunload', handleUnload);

      return () => {
        postLogEvent('finish', currentLogParams);
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
    return null;
  }, [didPlay]);

  const placeholderImg = () => (
    <img
      style={styles.placeholderImage}
      src={fgfPlaceholder}
      alt="blurry blue background with a band"
    />
  );

  const placeholderText = () => {
    const fontSize = isSmall ? 'h4' : isMedium ? 'h2' : 'h1';

    return (
      <Box sx={styles.placeholderText}>
        <Box display={{ xs: 'none', sm: 'block' }} marginBottom={8}>
          <img src={videoPlay} alt="video camera icon with a play button" />
        </Box>
        <Typography
          variant={fontSize}
          color="whiteWedding.main"
          sx={{ textShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)' }}>
          Feel good Friday
          <br />
          Is coming soon!
        </Typography>
      </Box>
    );
  };

  const fgfPlaceholderContent = () => (
    <Box width="100%">
      <Box style={styles.placeholderContainer}>
        {placeholderImg()}
        {placeholderText()}
      </Box>
    </Box>
  );

  const videoErrorContent = () => (
    <Box className="videoSkeleton" style={styles.errorContainer}>
      {placeholderImg()}
      <Box sx={styles.placeholderText}>
        <Box display={{ xs: 'none', sm: 'block' }} marginBottom={8}>
          <Typography variant={isMedium ? 'h5' : 'h4'} color="whiteWedding.main">
            <BrokenImageIcon fontSize="large" />
            <br />
            Sorry, we have run into an error.
            <br />
            Please try refreshing the page and ensure your IT department allows Vimeo content.
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const videoPlaceholderContent = () => {
    if (videoError) return videoErrorContent();

    if (videoLoaded) return null;

    return <Skeleton className="videoSkeleton" variant="rectangular" />;
  };

  const videoContainer = () => (
    <Box minWidth={{ xs: '100%', md: 567 }}>
      {videoPlaceholderContent()}

      <Vimeo
        className="fgfVideo"
        video={data.vimeo_id}
        key={data.vimeo_id}
        playsInline={false}
        responsive
        onPlay={handlePlay}
        onEnd={handleEnd}
        onLoaded={handleVideoLoaded}
        onError={handleVideoError}
        dnt={!CookieUtils.analyticsConsentGiven()}
        style={{
          display: !videoLoaded || videoError ? 'none' : 'inherit'
        }}
      />
      {fileUrl && (
        <Box height={72} width={{ xs: '100%', lg: '75%' }} maxWidth={380} marginTop={16}>
          <FGFDownloadButton downloadPath={fileUrl} />
        </Box>
      )}
    </Box>
  );

  const video = () =>
    data ? (
      <Box key={data.vimeo_id}>
        <Typography variant="h2" sx={{ marginBottom: 21 }}>
          {ROCKSTEADY_AGE_GROUPS[camelCase(data.age_group)].years}{' '}
          <Typography component="span" sx={theme.typography.h3}>
            ({data.name})
          </Typography>
        </Typography>

        <Box display="flex" gap={28} flexDirection={{ xs: 'column', md: 'row' }}>
          {videoContainer()}
          <Typography
            variant="body"
            // If the description contains an unordered list, remove the margin that is assigned to lists by default
            sx={{ '& ul': { margin: 0, paddingLeft: 12 } }}>
            <ReactMarkdown>{data.description}</ReactMarkdown>
          </Typography>
        </Box>
      </Box>
    ) : (
      fgfPlaceholderContent()
    );

  return (
    <Box
      backgroundColor={bgColor}
      sx={{ ...styles.container, justifyContent: loading ? 'center' : 'flex-start' }}>
      {loading ? <CircularProgress sx={{ color: 'white' }} /> : video()}
    </Box>
  );
}

const styles = {
  container: {
    borderRadius: '0px 0px 25px 25px',
    marginBottom: 32,
    padding: 32,
    display: 'flex'
  },
  errorContainer: { position: 'relative' },
  placeholderContainer: { width: '100%', position: 'relative', overflow: 'hidden' },
  placeholderImage: { objectFit: 'cover', height: '100%', width: '100%' },
  placeholderText: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};
