/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Page } from '@rocksteady-music-school/rms-ui';
import ImpactDashboardContainer from '../components/ImpactReport/ImpactDashboardContainer';
import usePageVisitLogger from '../hooks/usePageVisitLogger';
import ImpactTimelineContainer from '../components/ImpactReport/ImpactTimelineContainer';
import { useAuth } from '../context/Auth/useAuth';
import { useAttributes } from '../utils/jsonApiHelpers';
import { useSchools } from '../context/Schools/useSchools';
import ErrorPage from './ErrorPage';

export default function ImpactReportPage() {
  const { user } = useAuth();
  const { basic } = useAttributes(user);
  const { focussedSchoolIsActive } = useSchools();

  if (!focussedSchoolIsActive || basic) {
    return <ErrorPage content="pageNotFound" />;
  }

  usePageVisitLogger();

  const [fetchError, setFetchError] = useState(false);

  const title = () => (
    <Typography variant="h1" mt={{ xs: 0, md: 24 }} mb={{ xs: 24, sm: 80 }}>
      Impact Report
    </Typography>
  );

  const pageContent = () => {
    return (
      <Box>
        {title()}
        <ImpactDashboardContainer setFetchError={setFetchError} />
        <ImpactTimelineContainer setFetchError={setFetchError} />
      </Box>
    );
  };

  return <Page error={fetchError}>{pageContent()}</Page>;
}
