/* eslint-disable class-methods-use-this */
import axios from 'axios';

class HTTPWrapper {
  patch = ({ url, data, config } = {}) => axios.patch(url, data, config);

  get = ({ url, config, paramsSerializer } = {}) =>
    axios.get(url, { params: config?.params, paramsSerializer });

  delete = ({ url, config } = {}) => axios.delete(url, config);

  post = ({ url, data, config } = {}) => axios.post(url, data, config);

  setAuthRequestInterceptor = (handleIntercept) => {
    axios.interceptors.request.use(handleIntercept);
  };

  setAuthResponseInterceptors = (handleSuccessIntercept, handleErrorIntercept) => {
    axios.interceptors.response.use(handleSuccessIntercept, handleErrorIntercept);
  };
}

export default new HTTPWrapper();
