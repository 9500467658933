import React from 'react';
import { useAuth } from '../context/Auth/useAuth';
import { useSchools } from '../context/Schools/useSchools';
import { useAttributes } from '../utils/jsonApiHelpers';
import TimetablePage from './TimetablePage';
import FeelGoodFridayPage from './FeelGoodFridayPage';

export default function HomePage() {
  const { user } = useAuth();
  const { basic } = useAttributes(user);
  const { focussedSchoolIsActive } = useSchools();

  return focussedSchoolIsActive && !basic ? <TimetablePage /> : <FeelGoodFridayPage />;
}
