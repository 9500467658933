/* eslint-disable react/forbid-prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { SchoolsContext } from './SchoolsContext';
import { useAuth } from '../Auth/useAuth';
import SchoolAPI from '../../api/SchoolAPI';

const EMPTY_STATE = Object.freeze({
  schools: [],
  timetables: [],
  focussedSchoolId: null
});

export default function SchoolsProvider({ children, defaultState }) {
  const { isAuthenticated, schoolContactLinks } = useAuth();

  const [getSchoolsPending, setGetSchoolsPending] = useState(false);
  const [schools, setSchools] = useState(defaultState.schools);
  const [timetables, setTimetables] = useState(defaultState.timetables);
  const [focussedSchoolId, setFocussedSchoolId] = useState(defaultState.focussedSchoolId);

  const clearState = () => {
    setSchools(EMPTY_STATE.schools);
    setTimetables(EMPTY_STATE.timetables);
    setFocussedSchoolId(EMPTY_STATE.focussedSchoolId);
  };

  const handleSuccessfulSchoolsFetch = ({ data }) => {
    setSchools(data.data);
    setTimetables(data.included);
    if (!focussedSchoolId || !data.data.some(({ id }) => id === focussedSchoolId)) {
      const firstActiveSchool = data.data.find(({ relationships }) =>
        Boolean(relationships.active_timetables.data)
      );
      // Set first active school as focussed, else just the first one.
      setFocussedSchoolId(firstActiveSchool?.id || data.data[0].id);
    }
  };

  const getSchools = () => {
    setGetSchoolsPending(true);
    SchoolAPI.getSchools()
      .then(handleSuccessfulSchoolsFetch)
      .catch((err) => {
        if (err.response?.status !== 401) {
          throw err;
        }
      })
      .finally(() => {
        setGetSchoolsPending(false);
      });
  };

  useEffect(() => {
    if (!isAuthenticated && schools.length) {
      clearState();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Reacts to changes in auth state, schools or schoolContactLinks.
    // Providing we are authenticated and we don't currently hold schools relevant to the stored
    // schoolContactLinks, we're good to fetch!
    const schoolIds = schools.map(({ id }) => id);
    const schoolContactLinksSchoolIds = schoolContactLinks.map(
      ({ relationships }) => relationships.schools_in_house_datum.data.id
    );
    if (isAuthenticated && !intersection(schoolIds, schoolContactLinksSchoolIds).length) {
      getSchools();
    }
  }, [isAuthenticated, schools, schoolContactLinks]);

  const focussedSchool = useMemo(
    () => schools.find(({ id }) => id === focussedSchoolId),
    [schools, focussedSchoolId]
  );

  const focussedSchoolTimetables = useMemo(
    () =>
      timetables.filter(
        ({ relationships }) => relationships.schools_in_house_datum.data.id === focussedSchoolId
      ),
    [timetables, focussedSchoolId]
  );

  const focussedSchoolContactLink = useMemo(
    () =>
      schoolContactLinks.find(
        ({ relationships }) => relationships.schools_in_house_datum.data.id === focussedSchoolId
      ),
    [schoolContactLinks, focussedSchoolId]
  );

  const updateFocussedSchool = useCallback(
    (params) =>
      SchoolAPI.updateSchoolsInHouseData(focussedSchool.attributes.urn, params).then(({ data }) => {
        const otherSchools = schools.filter(({ id }) => id !== data.data.id);
        setSchools([...otherSchools, data.data]);
      }),
    [focussedSchool, schools]
  );

  const focussedSchoolIsActive = Boolean(focussedSchoolTimetables.length);

  const contextValue = useMemo(
    () => ({
      getSchoolsPending,
      schools,
      focussedSchool,
      focussedSchoolTimetables,
      focussedSchoolIsActive,
      // setFocussedSchoolId,
      updateFocussedSchool,
      focussedSchoolContactLink
    }),
    [getSchoolsPending, updateFocussedSchool, focussedSchoolTimetables, focussedSchoolContactLink]
  );

  return <SchoolsContext.Provider value={contextValue}>{children}</SchoolsContext.Provider>;
}

SchoolsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.shape({
    schools: PropTypes.arrayOf(PropTypes.object),
    timetables: PropTypes.arrayOf(PropTypes.object),
    focussedSchoolId: PropTypes.string
  })
};

SchoolsProvider.defaultProps = {
  defaultState: EMPTY_STATE
};
