/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Card, Page } from '@rocksteady-music-school/rms-ui';
import TimetableAPI from '../api/TimetableAPI';
import BandsContainer from '../components/BandsContainer/BandsContainer';
import TimetableContainer from '../components/TimetableContainer/TimetableContainer';
import HomePageHeader from '../components/HomePageHeader/HomePageHeader';
import ErrorPage from './ErrorPage';
import { useAuth } from '../context/Auth/useAuth';
import { useSchools } from '../context/Schools/useSchools';
import { useAttributes } from '../utils/jsonApiHelpers';
import bursaryKids from '../assets/images/AutomatedEmailList.png';
import fundedKids from '../assets/images/ManageSchoolFunded.png';
import paths from '../consts/paths';

export default function TimetablePage() {
  const { user } = useAuth();
  const { admin, basic } = useAttributes(user);
  const { focussedSchoolTimetables, focussedSchoolIsActive } = useSchools();

  if (!focussedSchoolIsActive || basic) {
    return <ErrorPage content="pageNotFound" />;
  }

  const [selectedTimetableId, setSelectedTimetableId] = useState(null);
  const [bandsDataLoading, setBandsDataLoading] = useState(true);
  const [bandsData, setBandsData] = useState({ bands: [] });
  const [fetchError, setFetchError] = useState(false);

  const timetables = useMemo(
    () =>
      focussedSchoolTimetables.map(({ id, attributes }) => ({
        id: Number(id),
        display_day_time_slot: attributes.display_day_time_slot
      })),
    [focussedSchoolTimetables]
  );

  const selectedTimetable = useMemo(
    () => timetables.find(({ id }) => id === selectedTimetableId),
    [timetables, selectedTimetableId]
  );

  const getBandsData = (id) => {
    setBandsDataLoading(true);

    TimetableAPI.getBandsData(id)
      .then((res) => {
        setBandsData(res.data);
        setBandsDataLoading(false);
      })
      .catch(() => setFetchError(true));
  };

  useEffect(() => {
    if (timetables.length) setSelectedTimetableId(timetables[0].id);
  }, [timetables]);

  useEffect(() => {
    if (selectedTimetableId) getBandsData(selectedTimetableId);
  }, [selectedTimetableId]);

  const handleTTChange = ({ target }) => {
    setSelectedTimetableId(target.value);
  };

  const ttDropdown = () => (
    <Box width={{ xs: '100%', sm: 266 }} mb={24}>
      <FormControl fullWidth>
        <InputLabel id="select-tt-label">Select Timetable</InputLabel>
        <Select
          labelId="select-tt-label"
          id="select-tt"
          value={selectedTimetableId || ''}
          label="Select Timetable"
          onChange={handleTTChange}>
          {timetables.map((tt) => (
            <MenuItem key={tt.id} value={tt.id}>
              {tt.display_day_time_slot}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  const timetableContent = () => {
    if (selectedTimetable) {
      return (
        <TimetableContainer
          id={selectedTimetableId}
          ttDayTimeSlot={selectedTimetable?.display_day_time_slot}
          bandsData={bandsData}
          bandsDataLoading={bandsDataLoading}
          setFetchError={setFetchError}
        />
      );
    }
    return null;
  };

  const bandsContent = () => {
    if (selectedTimetable) {
      return <BandsContainer bandsData={bandsData} bandsDataLoading={bandsDataLoading} />;
    }
    return null;
  };

  const bursaryAndFundedCards = () =>
    admin && (
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 32, xl: 64 }}
        mt={32}>
        <Card
          altText="Rocksteady band playing instruments"
          buttonProps={{ label: 'Use Your Free Bursary Place', href: paths.MATCH_FUNDING }}
          imgRight
          summary="Rocksteady match every School Funded Space with a Rocksteady Bursary. What's more, the first Bursary space is provided up-front."
          thumbnail={bursaryKids}
          title="Support more children through Match-Funding"
        />
        <Card
          altText="Rocksteady band playing instruments"
          buttonProps={{ label: 'View Your Impact Report', href: paths.IMPACT_REPORT }}
          imgRight
          summary="Connect with your pupils exciting musical journey and access instant stats on the impact Rocksteady is having in your school."
          thumbnail={fundedKids}
          title="IMPACT REPORT AND SCHOOL TIMELINE"
        />
      </Box>
    );

  return (
    <Page error={fetchError}>
      <HomePageHeader setFetchError={setFetchError} />
      {timetables.length > 1 ? ttDropdown() : null}
      {timetableContent()}
      {bandsContent()}
      {bursaryAndFundedCards()}
    </Page>
  );
}
