import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Box, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import * as dayjsAdvancedFormat from 'dayjs/plugin/advancedFormat';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Bugsnag from './utils/BugsnagClient';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MuiTheme from './styles/MuiTheme';
import OutdatedBrowserContent from './components/OutdatedBrowserContent/OutdatedBrowserContent';
import ErrorPageContent from './components/ErrorPageContent/ErrorPageContent';
import NotistackSnackbarProvider from './components/NotistackSnackbarProvider';

import './assets/fonts/227de6ba-fef9-435d-8693-a53a76aa1add.eot';
import './assets/fonts/dae937ec-159f-4710-9b2d-9470e24807ba.woff2';
import './assets/fonts/c8856bf2-4d23-4e30-9fb1-eede15b787b6.woff';
import './assets/fonts/376f433f-1486-4168-932f-06352c8291f8.ttf';

dayjs.extend(dayjsAdvancedFormat);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function ErrorFallbackComponent() {
  return (
    <Box p={32}>
      <ErrorPageContent />
    </Box>
  );
}

const renderContent = () => {
  if (isIE) {
    return <OutdatedBrowserContent />;
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <StyledEngineProvider injectFirst>
        <NotistackSnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ErrorBoundary>
          </LocalizationProvider>
        </NotistackSnackbarProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>{renderContent()}</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
