export default class StringUtils {
  static humanize = (str) => {
    if (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, (m) => m.toUpperCase());
    }
    return null;
  };

  static titleize = (str) => {
    if (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, (m) => m.toUpperCase())
        .replace(/\s[a-z]/, (m) => m.toUpperCase());
    }
    return null;
  };

  static humanizedStringToId = (str) => str.replace(/[ ,]+/g, '_').toLowerCase();

  static passwordIsNotComplexEnough = (string) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
    return string.length < 8 || !string.match(regex);
  };

  static isEmailIsh = (string) => !!string && string.indexOf('.') > -1 && string.indexOf('@') > -1;

  static isTelephoneIsh = (string) => {
    // https://stackoverflow.com/a/11518538
    const regex =
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

    return !!string && regex.test(string);
  };

  static formatDigitsOnly = (value) => value.replace(/[^\d]/gi, '');

  static upperCaseFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);

  static schoolYearAsString = (year) => {
    const yearIndex = ['R', '0', 0].indexOf(year);

    if (yearIndex >= 0) {
      return 'R';
    }
    return year;
  };

  static b64toBlob = (base64, type = 'application/pdf') =>
    fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

  static containsEmoji = (string) => /\p{Extended_Pictographic}/u.test(string);
}
