/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import contactDetails from '../../consts/contactDetails';
import paths from '../../consts/paths';
import { useSchools } from '../../context/Schools/useSchools';
import { useMeta } from '../../utils/jsonApiHelpers';
import DateUtils from '../../utils/DateUtils';

export default function SatsPreferences({ satsPreferences, onSatsPreferenceChange }) {
  const { satsPreference, ks1Sats, satsRoomNotes } = satsPreferences;

  const { focussedSchool } = useSchools();
  const { sats_cutoff_date: satsCutoffDate } = useMeta(focussedSchool);

  const formIsLocked = DateUtils.isBeforeToday(satsCutoffDate);

  const radioOption = (value, label) => (
    <FormControlLabel disabled={formIsLocked} value={value} control={<Radio />} label={label} />
  );

  const lockedFormAlert = () =>
    formIsLocked && (
      <Alert severity="warning">
        This form is now locked. If you need to discuss anything please contact the{' '}
        <a href={`mailto:${contactDetails.SCHOOLS_EMAIL}`}>
          <Typography variant="bodyLink">Schools Hotline</Typography>
        </a>
        .
      </Alert>
    );

  const ks2Options = () => (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="sats-preference"
      value={satsPreference}
      onChange={onSatsPreferenceChange}>
      {radioOption(
        'normal_lessons',
        <Typography variant="body" color="fadeToGrey.main">
          Yes, lesson as normal, please
        </Typography>
      )}
      {radioOption(
        'reduced_noise_lessons',
        <Typography variant="body" color="fadeToGrey.main">
          Yes,{' '}
          <a href={paths.NOISE_REDUCED_LESSONS} target="_blank" rel="noopener noreferrer">
            <Typography variant="bodyLink">low-noise lessons</Typography>
          </a>
          , please
        </Typography>
      )}
      {radioOption(
        'no_lessons',
        <Typography variant="body" color="fadeToGrey.main">
          Sorry, lessons are unable to go ahead that week
        </Typography>
      )}
    </RadioGroup>
  );

  const ks2Alert = () =>
    satsPreference === 'no_lessons' && (
      <Alert severity="info" style={{ marginTop: 10, marginBottom: 8 }}>
        If we need to book a catch-up lesson to meet our lesson quota for your parents, we will be
        in touch.
      </Alert>
    );

  const roomChangesTextField = () => (
    <TextField
      id="sats-room-notes"
      label="Any room changes we should know about?"
      style={{ backgroundColor: 'white', width: '70%', marginBottom: 16, marginTop: 24 }}
      name="sats-room-notes"
      value={satsRoomNotes || ''}
      disabled={formIsLocked}
      inputProps={{ maxLength: 150 }}
      onChange={onSatsPreferenceChange}
    />
  );

  const ks1Options = () => (
    <FormGroup sx={{ display: 'block' }}>
      <FormControlLabel
        sx={{ display: 'table' }}
        control={
          <div style={{ display: 'table-cell' }}>
            <Checkbox
              disabled={formIsLocked}
              name="ks1-sats"
              checked={ks1Sats}
              onChange={onSatsPreferenceChange}
            />
          </div>
        }
        label={
          <Typography variant="body" component="p" color="fadeToGrey.main">
            KS1 SATs will impact Rocksteady lessons at my school
          </Typography>
        }
      />
    </FormGroup>
  );

  const ks1Alert = () =>
    ks1Sats && <Alert severity="info">We will be in touch with you shortly to discuss.</Alert>;

  const satsPrefs = () => (
    <Stack spacing={8}>
      <Typography variant="bodyBold" component="p">
        Choose how you would like to manage Rocksteady lessons during KS2 SATs week
      </Typography>
      {lockedFormAlert()}
      {ks2Options()}
      {ks2Alert()}
      {roomChangesTextField()}
      <Typography variant="bodyBold" component="p">
        KS1 SATs
      </Typography>
      {ks1Options()}
      {ks1Alert()}
    </Stack>
  );

  return satsPrefs();
}
