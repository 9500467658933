/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useState, useEffect } from 'react';
import { Page, Button } from '@rocksteady-music-school/rms-ui';
import CookieUtils from '../utils/CookieUtils';
import WindowUtils from '../utils/WindowUtils';
import CookiePolicyTable from '../components/CookiePolicyTable/CookiePolicyTable';
import { useAuth } from '../context/Auth/useAuth';

export default function CookiePolicyPage() {
  const { isAuthenticated } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const currentAnalyticsConsent = CookieUtils.analyticsConsentGiven();
  const currentFunctionalityConsent = CookieUtils.functionalityConsentGiven();

  const [analyticsConsentGiven, setAnalyticsConsentGiven] = useState(currentAnalyticsConsent);
  const [functionalityConsentGiven, setFunctionalityConsentGiven] = useState(
    currentFunctionalityConsent
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const data = window.localStorage.getItem('COOKIE_SNACK_BAR');
    // Show the SnackBar if we have COOKIE_SNACK_BAR in localStorage
    if (data !== null) {
      displaySnackMessage();
      // We then want to remove COOKIE_SNACK_BAR from localStorage after the snack bar becomes visible
      // since we don't want the SnackBar to appear on every refresh.
      window.localStorage.removeItem('COOKIE_SNACK_BAR');
    }
  }, []);

  const toggleAnalyticsConsent = () => {
    closeSnackbar();
    setAnalyticsConsentGiven(!analyticsConsentGiven);
  };

  const toggleFunctionalityConsent = () => {
    closeSnackbar();
    setFunctionalityConsentGiven(!functionalityConsentGiven);
  };

  const displaySnackMessage = () =>
    enqueueSnackbar('Done! Your cookie preferences have been updated.', { variant: 'success' });

  const updatePreferences = () => {
    CookieUtils.saveAnalyticsPreferences(analyticsConsentGiven);
    CookieUtils.saveFunctionalityPreferences(functionalityConsentGiven);
    WindowUtils.reload();
    // Due to the page reloading to apply the cookies, we cannot use the React state to show the successful SnackBar
    // We need to therefore make use of the localStorage, save the value there
    // and on refresh show the SnackBar if COOKIE_SNACK_BAR exists in localStorage
    window.localStorage.setItem('COOKIE_SNACK_BAR', true);
  };

  const acceptAll = () => {
    CookieUtils.saveAnalyticsPreferences(true);
    CookieUtils.saveFunctionalityPreferences(true);
    WindowUtils.reload();
    // Due to the page reloading to apply the cookies, we cannot use the React state to show the successful SnackBar
    // We need to therefore make use of the localStorage, save the value there
    // and on refresh show the SnackBar if COOKIE_SNACK_BAR exists in localStorage
    window.localStorage.setItem('COOKIE_SNACK_BAR', true);
  };

  const pageTitle = () => (
    <Typography variant="h1" marginBottom={26}>
      Cookie Policy
    </Typography>
  );

  const subtitle = (text) => (
    <Typography variant="h4" marginBottom={8}>
      {text}
    </Typography>
  );

  const paragraph = (text) => (
    <Typography variant="body" component="p" marginBottom={40}>
      {text}
    </Typography>
  );

  const toggleLabel = (text) => (
    <Typography variant="body" marginLeft={8}>
      {text}
    </Typography>
  );

  const goBackLink = () => (
    <Link to={-1} style={styles.backLink}>
      <ArrowCircleLeftOutlinedIcon color="primary" />
      <Typography variant="bodyLink" sx={{ ml: 8, textDecorationColor: 'white' }}>
        Back to previous page
      </Typography>
    </Link>
  );

  const cookiePreferencesForm = () => (
    <FormGroup sx={{ width: '100%', marginBottom: 40 }}>
      <FormControlLabel
        control={
          <Switch
            name="analyticsSwitch"
            onChange={toggleAnalyticsConsent}
            checked={analyticsConsentGiven}
          />
        }
        label={toggleLabel('Enable optional analytics cookies')}
      />
      <FormControlLabel
        control={
          <Switch
            name="functionalitySwitch"
            onChange={toggleFunctionalityConsent}
            checked={functionalityConsentGiven}
          />
        }
        label={toggleLabel('Enable optional site functionality cookies')}
      />
      <Stack direction={isMobile ? 'column' : 'row'} spacing={16} mt={16}>
        <Button
          label="Accept All"
          onClick={acceptAll}
          style={{ width: isMobile ? 'auto' : 'fit-content' }}
        />
        <Button
          label="Update Preferences"
          outlined
          onClick={updatePreferences}
          style={{ width: isMobile ? 'auto' : 'fit-content' }}
        />
      </Stack>
    </FormGroup>
  );

  const pageContent = () => (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          {goBackLink()}
          {pageTitle()}

          {subtitle('What is a cookie?')}
          {paragraph(
            'A cookie is a small file that can be placed on your device that allows companies to recognise and remember you. When you visit our site, the 3rd party video players we use may place analytics cookies on your device. This helps them collect data about their service and helps them display relevant content.'
          )}

          {subtitle('What happens if I disable cookies?')}
          {paragraph(
            'If you disable cookies, that will mean that your experience of using our website won’t be to its full potential, as some of the cookies help us to identify and resolve errors or display relevant content.'
          )}

          {subtitle('Your cookies preferences')}
          {cookiePreferencesForm()}

          {subtitle('What type of cookies do we use?')}
        </Grid>
      </Grid>

      <CookiePolicyTable />
    </>
  );

  return (
    <Box mt={isAuthenticated ? 0 : 24}>
      <Page>{pageContent()}</Page>
    </Box>
  );
}

const styles = {
  backLink: { display: 'flex', alignItems: 'center', marginBottom: '25px' }
};
