/* eslint-disable class-methods-use-this */
import HTTPWrapper from '../utils/HTTPWrapper';

class UserAPI {
  newRegistration = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/registration/new.json`
    });

  signIn = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/schools_portal_users/sign_in.json`,
      data
    });

  signOut = () =>
    HTTPWrapper.delete({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/schools_portal_users/sign_out.json`
    });

  getCurrentUser = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/current_user.json`
    });

  signUp = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/registration.json`,
      data
    });

  setPassword = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/password.json`,
      data
    });

  updatePassword = (data) =>
    HTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/password.json`,
      data
    });

  update = (schoolContactId, data) =>
    HTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/school_contacts/${schoolContactId}.json`,
      data
    });

  myRoleChangeRequests = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests.json`
    });

  requestRoleChange = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests.json`,
      data
    });

  cancelRoleChangeRequest = (id) =>
    HTTPWrapper.delete({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests/${id}.json`
    });

  unlockAccount = (token) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal_users/unlock?unlock_token=${token}`
    });

  resendUnlockInstructions = (data) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal_users/unlock`,
      data
    });
}

export default new UserAPI();
