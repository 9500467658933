import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from '@rocksteady-music-school/rms-ui';
import paths from '../../consts/paths';
import CookieUtils from '../../utils/CookieUtils';
import WindowUtils from '../../utils/WindowUtils';

function CookieConsentModal() {
  const { pathname } = useLocation();
  const [settingsViewed, setSettingsViewed] = useState(false);
  const [analyticsConsentGiven, setAnalyticsConsentGiven] = useState(false);
  const [functionalityConsentGiven, setFunctionalityConsentGiven] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (CookieUtils.cookiePreferencesSet() || pathname === paths.COOKIE_POLICY) {
      return;
    }

    CookieUtils.removeExistingAnalyticsCookies();
    CookieUtils.removeExistingFunctionalityCookies();
    setOpen(true);
  }, []);

  const toggleAnalyticsConsent = () => setAnalyticsConsentGiven(!analyticsConsentGiven);

  const toggleFunctionalityConsent = () => setFunctionalityConsentGiven(!functionalityConsentGiven);

  const onViewSettingsSelect = () => setSettingsViewed(true);

  const closeAndReload = () => {
    setOpen(false);
    WindowUtils.reload();
  };

  const saveAndClose = () => {
    CookieUtils.saveAnalyticsPreferences(analyticsConsentGiven);
    CookieUtils.saveFunctionalityPreferences(functionalityConsentGiven);
    closeAndReload();
  };

  const acceptAll = async () => {
    CookieUtils.saveAnalyticsPreferences(true);
    CookieUtils.saveFunctionalityPreferences(true);
    closeAndReload();
  };

  const essentialLabel = (
    <Typography variant="body" component="p">
      <Typography variant="bodyBold">Essential Functional Cookies:</Typography> These cookies are
      essential to ensure the website works and can not be disabled. Using this tool will set a
      cookie on your device to remember your preferences.
    </Typography>
  );

  const analyticsLabel = (
    <Typography variant="body" component="p">
      <Typography variant="bodyBold">Analytics Cookies:</Typography> Click this option to allow
      cookies that show us how the website is being used, which helps us improve it.
    </Typography>
  );

  const functionalLabel = (
    <Typography variant="body" component="p">
      <Typography variant="bodyBold">Optional Functional Cookies:</Typography> Click this option to
      allow cookies that improve your experience when using the website.
    </Typography>
  );

  const essentialCheckbox = (
    <FormControlLabel
      sx={{ display: 'table' }}
      control={
        <div style={{ display: 'table-cell' }}>
          <Checkbox name="essentialCookies" checked disabled />
        </div>
      }
      label={essentialLabel}
    />
  );

  const analyticsCheckbox = (
    <FormControlLabel
      sx={{ display: 'table' }}
      control={
        <div style={{ display: 'table-cell' }}>
          <Checkbox
            name="analyticsCookies"
            checked={analyticsConsentGiven}
            onChange={toggleAnalyticsConsent}
          />
        </div>
      }
      label={analyticsLabel}
    />
  );

  const functionalityCheckbox = (
    <FormControlLabel
      sx={{ display: 'table' }}
      control={
        <div style={{ display: 'table-cell' }}>
          <Checkbox
            name="functionalityCookies"
            checked={functionalityConsentGiven}
            onChange={toggleFunctionalityConsent}
          />
        </div>
      }
      label={functionalLabel}
    />
  );

  const basicModalContent = () => (
    <Typography variant="body" component="p" mb={12}>
      We use cookies on our website to help with your browsing experience. More information on our
      use of cookies can be found in our{' '}
      <Link to={paths.COOKIE_POLICY} rel="noopener noreferrer" target="_blank">
        <Typography variant="bodyLink">cookie policy.</Typography>
      </Link>{' '}
      By clicking Accept All you consent to the use of all cookies on our website however you can
      click Settings to provide a controlled consent.
    </Typography>
  );

  const settingsModalContent = () => (
    <>
      <Typography variant="body" component="p" mb={12}>
        We use essential functional cookies to ensure this site works well and if selected, optional
        cookies to improve your experience. For more detailed information about the cookies we use,
        or to manage your preferences at any time, see our{' '}
        <Link to={paths.COOKIE_POLICY} rel="noopener noreferrer" target="_blank">
          <Typography variant="bodyLink">cookies page</Typography>
        </Link>
        .
      </Typography>
      <FormGroup>
        {essentialCheckbox}
        {analyticsCheckbox}
        {functionalityCheckbox}
      </FormGroup>
    </>
  );

  const settingsContentButtonProps = [
    { label: 'Save and Close', outlined: true, onClick: saveAndClose }
  ];

  const basicContentButtonProps = [
    { label: 'Settings', outlined: true, onClick: onViewSettingsSelect },
    { label: 'Accept All', onClick: acceptAll }
  ];

  return (
    <Modal
      open={open}
      modalEntities={[
        {
          title: 'Cookie Consent',
          content: settingsViewed ? settingsModalContent() : basicModalContent(),
          buttonProps: settingsViewed ? settingsContentButtonProps : basicContentButtonProps
        }
      ]}
    />
  );
}

export default CookieConsentModal;
