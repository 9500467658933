/* eslint-disable no-use-before-define */
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import paths from '../consts/paths';
import { useAuth } from '../context/Auth/useAuth';
import preLoginImg from '../assets/images/prelogin.png';
import white16YrBadge from '../assets/images/rocksteady-16-yr-badge-white.png';
import AuthInfoPanel from '../components/AuthInfoPanel/AuthInfoPanel';

export default function AuthLayout() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === '/login';
  const theme = useTheme();
  const slideInRef = useRef(null);

  useEffect(() => {
    // Redirect the user to the Home screen if they're logged in and trying to access a pre-login page
    if (isAuthenticated) navigate(paths.ROOT, { replace: true });
  }, []);

  const isMedScreenOrUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const content = () => (
    <Box ref={slideInRef}>
      <Grid container sx={styles.loginContainer}>
        <Grid item xs={12} sm={10} md={8} lg={5} sx={{ zIndex: 1 }}>
          <Outlet context={[slideInRef]} />
        </Grid>

        {isLogin && (
          <Grid item xs={12} sm={10} md={8} lg={7} sx={{ zIndex: 1 }}>
            <AuthInfoPanel />
          </Grid>
        )}
      </Grid>
      {isMedScreenOrUp && (
        <img
          src={preLoginImg}
          alt="kids in a rocksteady lesson"
          style={{ position: 'absolute', top: 0, right: 0, width: '60%' }}
        />
      )}
      {!isMobile && (
        <Box sx={styles.white16YrBadgeContainer}>
          <img
            src={white16YrBadge}
            alt="celebrating 16 years of rocksteady"
            style={{ width: 181 }}
          />
        </Box>
      )}
    </Box>
  );

  return content();
}

const styles = {
  loginContainer: {
    alignItems: 'flex-end',
    px: { xs: 16, lg: 0 },
    marginTop: '24px'
  },
  white16YrBadgeContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: { xs: 8, md: 24 },
    paddingRight: { xs: 8, md: 33 },
    zIndex: 100
  }
};
