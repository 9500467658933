/* eslint-disable class-methods-use-this */

import QueryString from 'qs';
import HTTPWrapper from '../utils/HTTPWrapper';

class SchoolAPI {
  findSchools = (query) => {
    const params = {
      filter: {
        name_or_urn_or_school_postcode_or_school_town_i_cont: query,
        school_establishmentstatus_name_not_eq: 'Closed',
        school_typeofestablishment_name_not_in: 'British schools overseas,Offshore schools'
      },
      fields: {
        schools_in_house_datum: 'name_with_town_and_postcode'
      }
    };
    const paramsSerializer = (p) => {
      return QueryString.stringify(p, { arrayFormat: 'brackets', encode: false });
    };
    return HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/v1/schools_in_house_data/search.json`,
      config: { params },
      paramsSerializer
    });
  };

  getSchoolDashboardData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/mini_dashboard`
    });

  getSchools = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools_in_house_data.json`
    });

  updateSchoolsInHouseData = (urn, data) =>
    HTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/schools_in_house_datum.json`,
      data
    });

  getTeamData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/team.json`
    });

  getDBSInfo = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/dbs_info.json`
    });

  getMatchFundingData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/match_funding_info.json`
    });

  getImpactDashboardData = (urn) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/impact_dashboard_data.json?`
    });

  getImpactTimelineData = (urn, page) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/schools/${urn}/impact_timeline_data.json?page=${page}`
    });
}

export default new SchoolAPI();
