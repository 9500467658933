/* eslint-disable react/forbid-prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import Bugsnag from '@bugsnag/js';
import { AuthContext } from './AuthContext';
import UserAPI from '../../api/UserAPI';
import HTTPWrapper from '../../utils/HTTPWrapper';

const TOKEN_KEY = 'rsm_schools_portal_jwt';
const getAuthToken = () => localStorage.getItem(TOKEN_KEY);
const setAuthToken = (value) => localStorage.setItem(TOKEN_KEY, value);
const removeAuthToken = () => localStorage.removeItem(TOKEN_KEY);

export function addAuthTokenToRequestHeaders(config) {
  if (!getAuthToken()) {
    return config;
  }

  return {
    ...config,
    headers: { ...config.headers, Authorization: getAuthToken() }
  };
}

export function storeAuthTokenFromResponseHeaders(response) {
  if (response.headers.authorization) {
    setAuthToken(response.headers.authorization);
  }

  return response;
}

export function unauthenticatedErrorResponseHandler(callback) {
  return (error) => {
    // Ensures token is removed and state emptied upon 401

    if (error.response?.status === 401) {
      removeAuthToken();
      callback(error);
    }

    return Promise.reject(error);
  };
}

const EMPTY_STATE = Object.freeze({
  user: null,
  schoolContact: null,
  schoolContactLinks: []
});

export default function AuthProvider({ children, defaultState }) {
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState(defaultState.user);
  const [schoolContact, setSchoolContact] = useState(defaultState.schoolContact);
  const [schoolContactLinks, setSchoolContactLinks] = useState(defaultState.schoolContactLinks);

  useEffect(() => Bugsnag.setUser(user?.id), [user]);

  const [getCurrentUserPending, setGetCurrentUserPending] = useState(false);
  const isAuthenticated = Boolean(getAuthToken() && user);

  const clearState = () => {
    setUser(EMPTY_STATE.user);
    setSchoolContact(EMPTY_STATE.schoolContact);
    setSchoolContactLinks(EMPTY_STATE.schoolContactLinks);
  };

  // Upon render, this sets up request/response interceptors with Axios (via HTTPWrapper file/module),
  // so that where a token exists in local storage, we place in the request headers,
  // and if a token is present in response headers, we place in local storage,
  // and if a 401 response is received, we remove any locally stored token and clear state.
  useEffect(() => {
    HTTPWrapper.setAuthRequestInterceptor(addAuthTokenToRequestHeaders);
    HTTPWrapper.setAuthResponseInterceptors(
      storeAuthTokenFromResponseHeaders,
      unauthenticatedErrorResponseHandler(clearState)
    );
  }, []);

  const handleSuccessfulAuthentication = ({ data }) => {
    setSchoolContact(data.included.find(({ type }) => type === 'school_contact'));
    setSchoolContactLinks(data.included.filter(({ type }) => type === 'school_contact_link'));
    setUser(data.data);
  };

  const getCurrentUser = () => {
    setGetCurrentUserPending(true);
    UserAPI.getCurrentUser()
      .then((res) => {
        handleSuccessfulAuthentication(res);
        setGetCurrentUserPending(false);
      })
      .catch((err) => {
        if (err.response?.status !== 401) {
          throw err;
        }
      });
  };

  // This fetches the current user if we have a token stored but empty state,
  // e.g. revisiting the site, or after a refresh.
  useEffect(() => {
    if (getAuthToken() && !user) {
      getCurrentUser();
    }
  }, [user]);

  const signIn = useCallback(
    (params) =>
      UserAPI.signIn({ schools_portal_user: params }).then((response) => {
        handleSuccessfulAuthentication(response);
        const returningUser = response.data.data.attributes.sign_in_count > 1;
        enqueueSnackbar(`Welcome${returningUser ? ' back' : ''} to Schools Portal!`, {
          variant: 'success'
        });
      }),
    []
  );

  const signOut = useCallback(
    () =>
      UserAPI.signOut().then(() => {
        removeAuthToken();
        clearState();
        enqueueSnackbar('Signed out successfully. See you soon!', { variant: 'success' });
      }),
    []
  );

  const updateSchoolContact = useCallback(
    (params) =>
      UserAPI.update(schoolContact.id, params).then(({ data }) => {
        setSchoolContact(data.data);
        setSchoolContactLinks(data.included);
      }),
    [schoolContact]
  );

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      getCurrentUserPending,
      user,
      schoolContact,
      updateSchoolContact,
      schoolContactLinks,
      signIn,
      signOut
    }),
    [isAuthenticated, getCurrentUserPending, user, updateSchoolContact, schoolContactLinks]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.shape({
    user: PropTypes.object,
    schoolContact: PropTypes.object,
    schoolContactLinks: PropTypes.arrayOf(PropTypes.object)
  })
};

AuthProvider.defaultProps = {
  defaultState: EMPTY_STATE
};
