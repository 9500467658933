/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { AppBar, Box, Toolbar, Button, Menu, MenuItem } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@rocksteady-music-school/rms-ui';
import { useAuth } from '../../context/Auth/useAuth';
import { useSchools } from '../../context/Schools/useSchools';
import { useAttributes } from '../../utils/jsonApiHelpers';
import paths from '../../consts/paths';
import logo from '../../assets/images/logo_rebranded.svg';
import dropdown from '../../assets/images/dropdown.svg';

const logoHeight = 64;
const logoWidth = 58;

export default function ResourceHubSiteHeader() {
  const navigate = useNavigate();
  const { isAuthenticated, signOut, schoolContact } = useAuth();
  const { full_name: fullName } = useAttributes(schoolContact);
  const { focussedSchoolIsActive } = useSchools();

  const [loggedInMenuAnchorEl, setLoggedInMenuAnchorEl] = useState(null);
  const loggedInMenuOpen = Boolean(loggedInMenuAnchorEl);

  const [loggedInMenuAnchorMobileEl, setLoggedInMenuAnchorMobileEl] = useState(null);
  const loggedInMenuMobileOpen = Boolean(loggedInMenuAnchorMobileEl);

  const [mainMenuAnchorMobileEl, setMainMenuAnchorMobileEl] = useState(null);
  const mainMenuMobileOpen = Boolean(mainMenuAnchorMobileEl);

  const toggleLoggedInMenuOpen = (event) => {
    if (loggedInMenuOpen) {
      setLoggedInMenuAnchorEl(null);
    } else {
      setLoggedInMenuAnchorEl(event.currentTarget);
    }
  };

  const toggleLoggedInMenuMobileOpen = (event) => {
    if (loggedInMenuMobileOpen) {
      setLoggedInMenuAnchorMobileEl(null);
    } else {
      setLoggedInMenuAnchorMobileEl(event.currentTarget);
    }
  };

  const toggleMainMenuMobileOpen = (event) => {
    if (mainMenuMobileOpen) {
      setMainMenuAnchorMobileEl(null);
    } else {
      setMainMenuAnchorMobileEl(event.currentTarget);
    }
  };

  const handleClickAway = () => {
    setLoggedInMenuAnchorEl(null);
  };

  const handleClickAwayMobile = () => {
    setLoggedInMenuAnchorMobileEl(null);
  };

  const navigateToBrowse = () => {
    setMainMenuAnchorMobileEl(null);
    navigate(paths.RESOURCE_HUB);
  };

  const logoContainer = () => (
    <Link to={paths.ROOT}>
      <Box
        component="img"
        sx={{
          height: logoHeight,
          width: logoWidth
        }}
        alt="Rocksteady Logo"
        src={logo}
      />
    </Link>
  );

  const siteIdentity = () => (
    <Box sx={styles.siteIdentityContainer} padding={{ xs: 20, md: 30 }}>
      {logoContainer()}
      <Box
        sx={styles.siteIdentityTitle}
        gap={{ xs: 5, md: 20 }}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'left', md: 'center' }}>
        <Typography
          variant="h2"
          color="white"
          component="span"
          sx={styles.header}
          fontSize={{ xs: 30, md: 40 }}
          lineHeight={{ xs: '24px', md: '48px' }}>
          Schools
        </Typography>
        <Typography
          variant="h3"
          color="white"
          component="span"
          sx={styles.subHeader}
          fontSize={{ xs: 15, md: 35 }}
          lineHeight={{ xs: '12px', md: '42px' }}>
          Video Resource Hub
        </Typography>
      </Box>
      <Box
        id="mainMenuMobile"
        onClick={toggleMainMenuMobileOpen}
        display={{ xs: 'flex', sm: 'flex', md: 'none' }}
        width={30}
        height={20}
        role="presentation">
        <MenuIcon sx={{ color: '#FFFFFF' }} />
      </Box>
      {siteIdentityMainMenuMobileContent()}
    </Box>
  );

  const siteIdentityMainMenuMobileContent = () => (
    <Menu
      anchorEl={mainMenuAnchorMobileEl}
      open={mainMenuMobileOpen}
      onClose={toggleMainMenuMobileOpen}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            background: '#2C6186',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: 0,
            marginTop: 40,
            paddingRight: 50,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 35,
              height: 35,
              bgcolor: '#2C6186',
              transform: 'translateY(-90%) rotate(0deg)',
              zIndex: 0,
              clipPath: `polygon(100% 0, 0% 100%, 100% 100%)`
            }
          }
        }
      }}>
      <MenuItem onClick={() => navigateToBrowse()}>
        <Typography
          style={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
            flex: '1 0 0',
            overflow: 'hidden',
            color: '#FFF',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            fontFamily: 'Barlow',
            fontSize: 15,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px'
          }}>
          Browse
        </Typography>
      </MenuItem>
    </Menu>
  );

  const authOptions = () => (
    <Box sx={styles.authOptions} padding={{ xs: '0px', md: '10px 30px' }}>
      <Box sx={styles.authButtons}>
        <Box sx={styles.authButtonsInner}>
          <Button className="rebrand-button" onClick={() => navigate(paths.RESOURCE_HUB_SIGN_UP)}>
            Create account
          </Button>
        </Box>
        <Box sx={styles.authButtonsInner}>
          <Button
            className="rebrand-button outlined"
            onClick={() => navigate(paths.RESOURCE_HUB_LOGIN)}>
            Sign in
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const loggedInMenu = () => (
    <Box sx={styles.loggedInMenu}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          id="loggedInMenu"
          onClick={toggleLoggedInMenuOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
            <Typography
              style={{
                display: '-webkit-box',
                // maxWidth: '100px', Note: we have this in Figma but it seems too restrictive
                // We might want it to be more flexible and change based on the screen size
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '1',
                flex: '1 0 0',
                overflow: 'hidden',
                color: '#FFF',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                fontFamily: 'Barlow',
                fontSize: 15,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '21px'
              }}>
              {fullName || 'User'}
            </Typography>
            <Box
              style={{
                display: 'flex',
                width: 40,
                padding: '9.75px 0px 10.25px 0px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '999px',
                background: '#FFF'
              }}>
              {fullName && (
                <Typography
                  style={{
                    color: '#252B5A',
                    textAlign: 'center',
                    fontFamily: '"Barlow Condensed"',
                    fontSize: 20,
                    fontStyle: 'italic',
                    fontWeight: 800,
                    lineHeight: '20px'
                  }}>
                  {`${fullName[0]}`}
                </Typography>
              )}
              {!fullName && <AddIcon sx={{ color: '#2C6186' }} />}
            </Box>
          </Box>
          <img src={dropdown} alt="dropdown" />
        </Box>
      </ClickAwayListener>
      {loggedInMenuContent()}
      {!focussedSchoolIsActive && (
        <Box sx={styles.signUpButton} onClick={() => navigate(paths.BAND_LESSONS)}>
          <Typography
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Barlow',
              fontSize: 10,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '8px',
              padding: '10px 0 0 30px',
              marginBottom: 5,
              zIndex: 2
            }}>
            Learn more about
          </Typography>
          <Typography
            style={{
              color: '#FFF',
              fontFamily: 'Barlow Condensed',
              textAlign: 'center',
              fontSize: 15,
              fontStyle: 'italic',
              fontWeight: 700,
              lineHeight: '12px',
              padding: '0 0 0 20px',
              textTransform: 'uppercase',
              zIndex: 2
            }}>
            In-School Lessons
          </Typography>
        </Box>
      )}
    </Box>
  );

  const loggedInMenuContent = () => (
    <Menu
      anchorEl={loggedInMenuAnchorEl}
      open={loggedInMenuOpen}
      onClose={toggleLoggedInMenuOpen}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            background: '#2C6186',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: 0,
            marginTop: 40,
            paddingRight: 50,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 35,
              height: 35,
              bgcolor: '#2C6186',
              transform: 'translateY(-90%) rotate(0deg)',
              zIndex: 0,
              clipPath: `polygon(100% 0, 0% 100%, 100% 100%)`
            }
          }
        }
      }}>
      <MenuItem onClick={() => navigate(paths.ROOT)}>
        <Box sx={styles.portalButton}>
          <Typography
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Barlow',
              fontSize: 10,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '8px',
              padding: '10px 0 0 30px',
              marginBottom: 5
            }}>
            Go to your
          </Typography>
          <Typography
            style={{
              color: '#FFF',
              fontFamily: 'Barlow Condensed',
              textAlign: 'center',
              fontSize: 15,
              fontStyle: 'italic',
              fontWeight: 700,
              lineHeight: '12px',
              padding: '0 0 0 20px',
              textTransform: 'uppercase'
            }}>
            Schools Portal
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem onClick={signOut}>
        <Typography
          style={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
            flex: '1 0 0',
            overflow: 'hidden',
            color: '#FFF',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            fontFamily: 'Barlow',
            fontSize: 15,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px'
          }}>
          Sign Out
        </Typography>
      </MenuItem>
    </Menu>
  );

  const loggedInMenuMobile = () => (
    <Box sx={styles.loggedInMenu}>
      <ClickAwayListener onClickAway={handleClickAwayMobile}>
        <Box
          id="loggedInMenuMobile"
          onClick={toggleLoggedInMenuMobileOpen}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
            <Typography
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '1',
                flex: '1 0 0',
                overflow: 'hidden',
                color: '#FFF',
                textAlign: 'center',
                textOverflow: 'ellipsis',
                fontFamily: 'Barlow',
                fontSize: 15,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                maxWidth: 120
              }}>
              {fullName || 'User'}
            </Typography>
            <Box
              style={{
                display: 'flex',
                width: 40,
                padding: '9.75px 0px 10.25px 0px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '999px',
                background: '#FFF'
              }}>
              {fullName && (
                <Typography
                  style={{
                    color: '#252B5A',
                    textAlign: 'center',
                    fontFamily: '"Barlow Condensed"',
                    fontSize: 20,
                    fontStyle: 'italic',
                    fontWeight: 800,
                    lineHeight: '21px'
                  }}>
                  {`${fullName ? fullName[0] : ''}`}
                </Typography>
              )}
              {!fullName && <AddIcon sx={{ color: '#2C6186' }} />}
            </Box>
          </Box>
          <img src={dropdown} alt="dropdown" />
        </Box>
      </ClickAwayListener>
      {loggedInMenuMobileContent()}
      {!focussedSchoolIsActive && (
        <Box sx={styles.signUpButton} onClick={() => navigate(paths.BAND_LESSONS)}>
          <Typography
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Barlow',
              fontSize: 10,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '8px',
              padding: '10px 0 0 30px',
              marginBottom: 5,
              zIndex: 2
            }}>
            Learn more about
          </Typography>
          <Typography
            style={{
              color: '#FFF',
              fontFamily: 'Barlow Condensed',
              textAlign: 'center',
              fontSize: 15,
              fontStyle: 'italic',
              fontWeight: 700,
              lineHeight: '12px',
              padding: '0 0 0 20px',
              textTransform: 'uppercase',
              zIndex: 2
            }}>
            In-School Lessons
          </Typography>
        </Box>
      )}
    </Box>
  );

  const loggedInMenuMobileContent = () => (
    <Menu
      anchorEl={loggedInMenuAnchorMobileEl}
      open={loggedInMenuMobileOpen}
      onClose={toggleLoggedInMenuMobileOpen}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            background: '#2C6186',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            borderRadius: 0,
            marginTop: 40,
            paddingRight: 50,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 35,
              height: 35,
              bgcolor: '#2C6186',
              transform: 'translateY(-90%) rotate(0deg)',
              zIndex: 0,
              clipPath: `polygon(100% 0, 0% 100%, 100% 100%)`
            }
          }
        }
      }}>
      <MenuItem onClick={() => navigate(paths.ROOT)}>
        <Box sx={styles.portalButton}>
          <Typography
            style={{
              color: '#FFF',
              textAlign: 'center',
              fontFamily: 'Barlow',
              fontSize: 10,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '8px',
              padding: '10px 0 0 30px',
              marginBottom: 5
            }}>
            Go to your
          </Typography>
          <Typography
            style={{
              color: '#FFF',
              fontFamily: 'Barlow Condensed',
              textAlign: 'center',
              fontSize: 15,
              fontStyle: 'italic',
              fontWeight: 700,
              lineHeight: '12px',
              padding: '0 0 0 20px',
              textTransform: 'uppercase'
            }}>
            Schools Portal
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem onClick={signOut}>
        <Typography
          style={{
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '1',
            flex: '1 0 0',
            overflow: 'hidden',
            color: '#FFF',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            fontFamily: 'Barlow',
            fontSize: 15,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px'
          }}>
          Sign Out
        </Typography>
      </MenuItem>
    </Menu>
  );

  const mobileVersion = () => (
    <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} flexDirection="column">
      {siteIdentity()}
      {isAuthenticated ? loggedInMenuMobile() : authOptions()}
    </Box>
  );

  const desktopVersion = () => (
    <Box
      display={{ xs: 'none', sm: 'none', md: 'flex' }}
      sx={styles.desktopWrapper}
      data-testid="desktop-wrapper">
      <AppBar position="relative" sx={styles.appBar}>
        <Toolbar sx={{ justifyContent: 'space-between', maxWidth: 1200, overflow: 'auto hidden' }}>
          {siteIdentity()}
          <Box sx={styles.navMenu}>
            <Box style={styles.navList}>
              <Box
                style={{
                  display: 'flex',
                  padding: 8,
                  alignItems: 'center',
                  gap: 14
                }}>
                <Link to={paths.RESOURCE_HUB} style={{ textDecoration: 'none' }}>
                  <Typography
                    style={{
                      color: '#FFF',
                      fontFamily: 'Barlow',
                      fontSize: 15,
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '21px'
                    }}>
                    Browse
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
          {isAuthenticated ? loggedInMenu() : authOptions()}
        </Toolbar>
      </AppBar>
    </Box>
  );

  return (
    <>
      {desktopVersion()}
      {mobileVersion()}
    </>
  );
}

const styles = {
  desktopWrapper: {
    width: '100vw',
    background: 'linear-gradient(180deg, #69C3C6 50%, rgba(105, 195, 198, 0.00) 100%)'
  },
  header: {
    color: '#FFF',
    fontFamily: '"Barlow Condensed"',
    fontStyle: 'italic',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  subHeader: {
    color: '#DAF0F1',
    fontFamily: '"Barlow Condensed"',
    fontStyle: 'italic',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  navMenu: {
    display: 'flex',
    height: 38,
    padding: 10,
    alignItems: 'center',
    gap: 30,
    flex: 1
  },
  authOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 40,
    flex: 3
  },
  authButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 14
  },
  authButtonsInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 5
  },
  siteIdentityContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 25,
    justifyContent: 'space-between',
    flex: 4
  },
  siteIdentityTitle: {
    display: 'flex',
    justifyContent: 'center'
  },
  appBar: {
    boxShadow: 'none',
    background: 'transparent',
    maxWidth: 1200,
    width: '100%',
    margin: 'auto'
  },
  loggedInMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    flex: 3
  },
  navList: {
    display: 'flex',
    alignItems: 'center',
    gap: 20
  },
  portalButton: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      height: 50,
      width: 130,
      zIndex: -1,
      background: '#252B5A',
      clipPath: `polygon(4% 0, 100% 0%, 96% 100%, 0% 100%)`
    }
  },
  signUpButton: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      height: 50,
      width: 150,
      zIndex: 1,
      background: '#252B5A',
      clipPath: `polygon(4% 0, 100% 0%, 94% 100%, 0% 100%)`
    }
  }
};
