/* eslint-disable no-use-before-define */

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@rocksteady-music-school/rms-ui';

import UserAPI from '../../api/UserAPI';
import paths from '../../consts/paths';
import { useAuth } from '../../context/Auth/useAuth';
import StringUtils from '../../utils/StringUtils';

const SetPasswordForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { signIn } = useAuth();

  const email = new URLSearchParams(search).get('email');
  const token = new URLSearchParams(search).get('token');

  const [signInPending, setSignInPending] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [passwordValues, setPasswordValues] = useState({ password: '', passwordConfirmation: '' });
  const [mismatchingPasswords, setMismatchingPasswords] = useState(false);
  const [error, setError] = useState(null);

  const passwordValid = () => !StringUtils.passwordIsNotComplexEnough(passwordValues.password);

  const passwordConfirmationValid = () =>
    !StringUtils.passwordIsNotComplexEnough(passwordValues.passwordConfirmation);

  const passwordInvalid = showValidationErrors && !passwordValid();

  const passwordConfirmationInvalid = showValidationErrors && !passwordConfirmationValid();

  const formValid = () => passwordValid() && passwordConfirmationValid();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowValidationErrors(false);
    setMismatchingPasswords(false);
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const errorHelperText = (invalidField, helperText) => {
    if (mismatchingPasswords) {
      return 'The passwords do not match';
    }
    if (invalidField) {
      return helperText;
    }
    return '';
  };

  const passwordField = () => (
    <TextField
      margin="normal"
      required
      name="password"
      label="New Password"
      type={showNewPassword ? 'text' : 'password'}
      id="new-password"
      autoComplete="new-password"
      autoFocus
      onChange={handleInputChange}
      error={mismatchingPasswords || passwordInvalid}
      helperText={errorHelperText(passwordInvalid, 'Please enter a new password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowNewPassword(!showNewPassword)}>
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  const confirmPasswordField = () => (
    <TextField
      margin="normal"
      required
      name="passwordConfirmation"
      label="Confirm Password"
      type={showConfirmPassword ? 'text' : 'password'}
      id="confirm-password"
      autoComplete="confirm-password"
      onChange={handleInputChange}
      error={mismatchingPasswords || passwordConfirmationInvalid}
      helperText={errorHelperText(passwordConfirmationInvalid, 'Please confirm your password')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle confirm password visibility"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );

  const setPasswordPayload = () => ({
    password: {
      reset_password_token: token,
      password: passwordValues.password,
      password_confirmation: passwordValues.passwordConfirmation
    }
  });

  const setPasswordAndSignIn = async () => {
    setSignInPending(true);

    try {
      await UserAPI.setPassword(setPasswordPayload());

      // This is only run if the setting of password succeeded.
      try {
        await signIn({ email, password: passwordValues.password });
        navigate(paths.ROOT, { replace: true });
      } catch (_err) {
        navigate(paths.LOG_IN, { replace: true });
      }
    } catch (err) {
      const message = err.response?.data?.error;
      if (typeof message === 'string' && message.match(/token has expired/)) {
        const redirectMessage = message.replace(/token/g, 'link');
        navigate(paths.REQUEST_PASSWORD_RESET, { state: { email, redirect: true } });
        enqueueSnackbar(redirectMessage, { variant: 'warning' });
      } else {
        setSignInPending(false);
        setError(err.response?.data?.error || err);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowValidationErrors(true);
    if (passwordValues.password !== passwordValues.passwordConfirmation) {
      setMismatchingPasswords(true);
    }

    if (formValid()) {
      setPasswordAndSignIn();
    }
  };

  const renderAlert = (type, message) => (
    <Alert severity={type} sx={{ mt: 15 }}>
      {message}
    </Alert>
  );

  const resetForm = () => (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Stack spacing={19} mt={13} sx={styles.formGroup}>
        {passwordField()}
        {confirmPasswordField()}
        {error &&
          renderAlert(
            'error',
            `An error occurred: ${error}. Please contact us if the problem persists.`
          )}
      </Stack>
      <Button
        type="submit"
        label="Create Password"
        color="secondary"
        style={styles.secondaryBtn}
        loading={signInPending}
      />
    </Box>
  );
  const text = (bodyText) => (
    <Typography variant="body" component="p">
      {bodyText}
    </Typography>
  );

  const emailTitle = () => (
    <Typography variant="bodyBold" mt={24}>
      Email address
    </Typography>
  );

  const emailAddress = () => (
    <Typography variant="body" color="fadeToGrey.main">
      {email}
    </Typography>
  );

  const content = () => (
    <Grid item>
      <Box display="flex" flexDirection="column">
        {text(
          'Password should be over 8 characters long and include at least one lowercase letter, uppercase letter and digit'
        )}
        {emailTitle()}
        {emailAddress()}
        {resetForm()}
      </Box>
    </Grid>
  );

  return content();
};

const styles = {
  formGroup: { width: { lg: '60%', xs: '100%' } },
  secondaryBtn: { width: 'fit-content', marginTop: 24 }
};

export default SetPasswordForm;
